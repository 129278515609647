import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    InputLabel,
    FormControl,
    Button,
    Select,
    MenuItem,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    select: {
        width: "100%",
    },
    text: {
        fontFamily: "LiberGrotesque-Bold",
    },
    cell: {
        cursor: 'pointer'
    },
    date: {
        fontFamily: "LiberGrotesque-Bold",
        fontSize: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        color: "#B91818",
    },
    slot: {
        fontFamily: "LiberGrotesque-SemiBold",
        fontSize: theme.spacing(2),     
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),   
    },
    selected: {
        color: "#fff",
        backgroundColor: "#B91818",
        width: "100%",
        borderRadius: theme.spacing(3)
    },
    disabled: {
        color: "#E2E2E2",
    },
    submit: {
        fontFamily: "LiberGrotesque-SemiBold",
        color: "#fff",
        backgroundColor: "#B91818",
        width: "100%",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#B91818",
        },
    },
}));

const dates = ['12', '13']
const slots = [
    ['13:30', '13:50', '14:10', '14:30', '14:50', '15:10', '15:30', '15:50', '16:10', '16:30', '16:50', '17:10', '17:30', '17:50', '18:10'],
    ['09:00', '09:10', '09:40', '10:00', '10:20', '10:40', '11:00', '11:20', '11:40', '12:00', '12:20', '12:40', '13:00', '13:20', '13:40']
]


const AppointmentFormComponent = ({
    buyers = [],
    sellers = [],
    appointments = [],
    ...props
}) => {

    const classes = useStyles();

    const onSelectDate = useCallback((date, slot) => {
        props.setFieldValue('date', date)
        props.setFieldValue('time', slot)
    }, [])

    console.log(props.values)

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Grid container>
                    {dates.map((date, index) =>
                        <Grid item xs={6} key={index}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" className={classes.date}>{date}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {slots[index].map((slot) => (
                                            <TableRow key={slot}>
                                                {appointments.find((item) => item.date === `2021-11-${date}` && item.time === `${slot}:00`) ?
                                                    <TableCell align='center'>
                                                        <Typography className={clsx(classes.slot, classes.disabled)}>
                                                            {slot}
                                                        </Typography>
                                                    </TableCell> :

                                                    <TableCell align='center' onClick={() => onSelectDate(date, slot)} className={classes.cell}>
                                                        <Typography className={clsx(classes.slot, props.values.date === date && props.values.time === slot ? classes.selected : null)}>
                                                            {slot}
                                                        </Typography>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <FormControl className={clsx(classes.margin, classes.select)} variant="filled">
                        <InputLabel>Buyers</InputLabel>
                        <Select
                            id="buyer"
                            value={props.values.buyers}
                            label="Buyers"
                            onChange={(event) => props.setFieldValue('buyer', event.target.value)}>
                            {buyers.length > 0 && buyers.map((buyer) =>
                                <MenuItem value={buyer.id} key={buyer.id}>{buyer.company_name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                {sellers.length > 0 && <Grid item xs={12}>
                    <FormControl className={clsx(classes.margin, classes.select)} variant="filled">
                        <InputLabel>Sellers</InputLabel>
                        <Select
                            id="seller"
                            value={props.values.sellers}
                            label="Sellers"
                            onChange={(event) => props.setFieldValue('seller', event.target.value)}>
                            {sellers.map((seller) =>
                                <MenuItem value={seller.id} key={seller.id}>{seller.company_name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>}

                <Grid item xs={12}>
                    <Button type='submit' className={clsx(classes.margin, classes.submit)} >
                        Conferma appuntamento
                    </Button>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default AppointmentFormComponent;
