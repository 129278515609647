import React, { Fragment, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import IconButtonComponent from "../buttons/IconButton.component";

const useStyles = makeStyles({
	root: {
		position: "absolute",
		alignItems: "center",
		margin: "auto",
	},
	imageWrapper: {
		maxHeight: "390px",
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		overflow: "hidden",
		position: "relative",
		"& img": {
			flexShrink: 0,
			minWidth: "100%",
			maxWidth: "100%",
			filter: "blur(1px) brightness(60%)",
		},
	},
	logoWrapper: {
		display: "flex",
		overflow: "hidden",
		borderRadius: "1rem",
		maxWidth: "50%",
		margin: "1.2rem auto",
		alignItems: "center",
		"@media (min-width: 1440px)": {
			maxWidth: "40%",
		},
		"@media (min-width: 2560px)": {
			maxWidth: "20%",
		},
		"& img": {
			flexShrink: 0,
			opacity: "100%",
			filter: "blur(0px)",
		},
	},
	textWrapper: {
		marginLeft: "2rem",
	},
	textTitle: {
		fontSize: "2.625rem",
		color: "#fff",
		fontFamily: "LiberGrotesque-Bold",
		// eslint-disable-line no-useless-computed-key
		["@media (max-width:426px)"]: {
			fontSize: "1rem",
		},
	},
	textSubtitle: {
		fontSize: "1rem",
		color: "#fff",
		fontFamily: "LiberGrotesque-SemiBold",
		marginBottom: "6px",
		// eslint-disable-line no-useless-computed-key
		["@media (max-width:426px)"]: {
			fontSize: "0.6rem",
			marginBottom: "3px",
		},
	},
	tab: {
		color: "#fff",
		textTransform: "capitalize",
	},
	".PrivateTabIndicator-root-60": {
		height: "5px",
	},
});

const DetailHeaderComponent = ({
	name,
	description,
	territory,
	country,
	chamber_commerce,
	city,
	web_site,
	logo,
	seller_id,
	user,
	img,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();

	const [mobileView, setMobileView] = useState(false);
	useEffect(() => {
		const setResponsiveness = () => setMobileView(window.innerWidth < 768);
		setResponsiveness();
		window.addEventListener("resize", () => setResponsiveness());
		return () => {
			window.removeEventListener("resize", () => setResponsiveness());
		};
	}, []);

	const handleClick = useCallback(() => {
		if (user) {
			history.push(`/dashboard/chat`);
		} else {
			history.push(`/login`)
		}
	}, [user])

	return (
		<Fragment>
			<Grid container className={classes.imageWrapper}>
				<img src={img} alt="" />
				<Grid container className={classes.root}>
					<Grid item xs={12} sm={6}>
						{mobileView != true ? <Box className={classes.logoWrapper}>
							{seller_id ?
								<Link to={`/food_sellers/${seller_id}`}>
									<img className={classes.media} src={_.replace(logo, 'open', 'uc')} />
								</Link>
								: <img className={classes.media} src={_.replace(logo, 'open', 'uc')} />}
						</Box> : null}

					</Grid>
					<Grid item xs={12} sm={6}>
						<Box style={{ marginLeft: mobileView ? "2rem" : '0rem' }}>
							{seller_id ?
								<Link to={`/food_sellers/${seller_id}`} style={{ textDecoration: 'none' }}>
									<Typography className={classes.textTitle}>{name}</Typography>
								</Link> :
								<Typography className={classes.textTitle}>{name}</Typography>
							}
							<Typography variant="body1" className={classes.textSubtitle}>
								{description}
							</Typography>
							<Typography variant="body1" className={classes.textSubtitle}>
								{t("food.product.area")}: {territory}
							</Typography>
							<Typography variant="body1" className={classes.textSubtitle}>
								{t("food.product.region")}: {chamber_commerce}
							</Typography>
							<Typography variant="body1" className={classes.textSubtitle}>
								{t("food.product.city")}: {city}
							</Typography>
							<Typography variant="body1" className={classes.textSubtitle}>
								{t("food.product.web")}: {web_site}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Box
				display="flex"
				justifyContent="flex-end"
				style={{ position: "relative", bottom: "1.5rem", marginRight: "2rem" }}
			>
				<IconButtonComponent
					onClick={handleClick}
					title={t("food.product.contact")}
					backgroundColor="#B91818"
					width="15rem"
				/>
			</Box>
		</Fragment>
	);
};

export default DetailHeaderComponent;
