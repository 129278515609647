import React from "react";
import { Card, CardContent, Box, Divider, Grid, Button } from "@material-ui/core";
import HeaderFilterCardComponent from "./components/HeaderFilterCard.component";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	filter: {
		textTransform: 'inherit',
		backgroundColor: '#B91818',
		width: '100%',
		padding: '10px',
		fontSize: '0.9rem',
		color: 'white',
		boxShadow: 'none',
		fontFamily: 'LiberGrotesque-Bold',
		'&:hover': {
			opacity: '0.7',
			backgroundColor: '#B91818',
			boxShadow: 'none',
		}
	},
	cancel: {
		textTransform: 'inherit',
		backgroundColor: 'white',
		width: '100%',
		padding: '10px',
		fontSize: '0.9rem',
		color: '#796D57',
		boxShadow: 'none',
		fontFamily: 'LiberGrotesque-Bold',
		'&:hover': {
			opacity: '0.7',
			backgroundColor: 'white',
			boxShadow: 'none',
		}
	},
}));


const noop = () => { }
const FilterComponent = ({
	children,
	onSubmit = noop,
	onReset = noop
}) => {

	const classes = useStyles();
	const { t } = useTranslation()

	return (
		<Card className={classes.root}>
			<CardContent className={classes.card}>
				<HeaderFilterCardComponent />				
				{children}
				<Divider />
				<Box p={2}>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<Button onClick={onReset} className={classes.cancel}>
								{t('filter.cancel')}
							</Button>
						</Grid>

						<Grid item xs={6}>
							<Button type='submit' onClick={onSubmit} className={classes.filter}>
								{t('filter.submit')}
							</Button>
						</Grid>
					</Grid>
				</Box>

			</CardContent>

		</Card>
	);
};

export default FilterComponent;
