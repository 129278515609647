import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Grid } from "@material-ui/core";

const CatalogueHeaderComponent = ({
	img,
	title,
	subtitle
}) => {

	const classes = useStyles();

	return (
		<Grid container className={classes.imageWrapper}>
			<img src={img} alt="" style={{objectFit: 'cover'}}/>
			<Grid container className={classes.root}>
				<Grid item xs={12} sm={8}>
					<Box p={4}>
						<Typography variant="h3" className={classes.title}>
							{title}
						</Typography>
						<Typography variant="h6" className={classes.description}>
							{subtitle}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	);
};


const useStyles = makeStyles({
	root: {
		position: "absolute",
		alignItems: "center",
	},
	imageWrapper: {
		maxHeight: "350px",
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		overflow: "hidden",
		position: "relative",
		"& img": {
			flexShrink: 0,
			minWidth: "100%",
			maxWidth: "100%",
			filter: "blur(1px) brightness(60%)",
		},
	},
	title: {		
		color: "#fff",
		fontFamily: 'LiberGrotesque-Bold',
		textShadow: '0px 0px 10px #000000',
		// eslint-disable-line no-useless-computed-key
		["@media (max-width:426px)"]: {
			fontSize: "1.5rem",
		},
	},
	description: {
		color: "#fff",
		fontFamily: 'LiberGrotesque-SemiBold',
		textShadow: '0px 0px 10px #000000',
		["@media (max-width:426px)"]: {
			fontSize: "1rem",
			marginBottom: "3px",
		},
	},
});


export default CatalogueHeaderComponent;
