import React, { Fragment } from "react";
import HeaderContainer from "../container/header/Header.container";
import FoodProductContainer from "../container/food/FoodProduct.container";

const FoodProductPage = () => {
    return (
        <Fragment>
            <HeaderContainer />
            <FoodProductContainer />            
        </Fragment>
    );
};

export default FoodProductPage;