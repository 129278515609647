import _ from 'lodash';

export const UsersEndpoints = {
    basePath: '/api/v1',
    endpoints: {
        me: {
            path: '/users/me/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
    }
}

export default UsersEndpoints;