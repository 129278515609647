import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import AppointmentDeleteContainer from "../../container/profile/AppointmentDelete.container";

const useStyles = makeStyles({
	root: {
		padding: "1rem",
		borderTopLeftRadius: "1rem",
		borderTopRightRadius: "1rem",
		borderTopWidth: "0.5rem",
		borderTop: "solid",
		borderTopColor: '#6EA4EF',
		margin: "1.5rem",
		alignItems: "center",
	},
	category: {
		borderRadius: "0.5rem",
		padding: "0.5rem",
		marginRight: "1rem",
		textAlign: "center",
		color: '#6EA4EF',
		backgroundColor: `#6EA4EF35`,
		"& .MuiTypography-body1": {
			color: '#6EA4EF',
			opacity: "100%",
			fontFamily: "LiberGrotesque-Bold",
		},
	},
	item: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: "0px",
	},
	list: {
		color: "#796D57",
		fontFamily: "LiberGrotesque-Bold",
		alignItems: "center",
		display: "flex",
		margin: "0.5rem 0rem",
		"& .MuiSvgIcon-root": {
			marginRight: "0.5rem",
			alignItems: "center",
		},
		"& svg": {
			alignItems: "center",
			fontSize: "1rem",
			margin: "0rem",
		},
	},
	footer: {
		marginTop: "1rem",
		display: "flex",
		justifyContent: "space-between",
	},
	pending: {
		color: "#B91818",
		fontFamily: "LiberGrotesque-Black",
		textTransform: "capitalize  ",
		alignItems: "center",
		display: "flex",
		margin: "1rem 0rem",
		"& svg": {
			marginRight: "0.5rem",
			alignItems: "center",
		},
	},
	iconButton: {
		textDecoration: "none",
		color: "#796D57",
		padding: '0.5rem'
	},
});
const MeetingCardListComponent = ({
	date,
	time,
	buyer,
	seller
}) => {

	const classes = useStyles();

	return (
		<Paper className={classes.root} elevation={7}>
			<Grid container style={{ justifyContent: 'space-around', alignItems: 'center' }}>
				<Grid item xs={12} md={2} className={classes.item}>
					<Typography className={classes.list}>
						<EventOutlinedIcon />
						{date}
					</Typography>
				</Grid>

				<Grid item xs={12} md={2} className={classes.item}>
					<Typography className={classes.list}>
						<WatchLaterOutlinedIcon />
						{time}
					</Typography>
				</Grid>

				<Grid item xs={12} md={3} className={classes.item}>
					<Typography
						className={classes.list}
						style={{ textTransform: "uppercase" }}
					>
						<PermIdentityOutlinedIcon />
						{buyer}
					</Typography>
				</Grid>

				<Grid item xs={12} md={4} className={classes.item}>
					<Typography
						className={classes.list}
						style={{ textTransform: "uppercase" }}
					>
						<PermIdentityOutlinedIcon />
						{seller}
					</Typography>
				</Grid>


				<Grid item xs={12} md={1} className={classes.item}>
					<AppointmentDeleteContainer />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default MeetingCardListComponent;
