import * as Yup from 'yup';

export const MessageChatValidationSchema = {
    schema: Yup.object().shape({
        'text': Yup.string().required('Campo obbligatorio'),
    }),
}

export const MessageChatInitialValues = {
    'user_to': '',
    'text': ''
}