import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: "LiberGrotesque-Bold",
        fontSize: "1rem",
    },
    info: {
        fontFamily: "LiberGrotesque-SemiBold",
        color: "#796D57",
        fontSize: '0.8rem'
    },

}));

const ChatFormComponent = ({
    onClick = () => { },
    data = [],
}) => {

    const classes = useStyles();

    return (
        <List>
            {data.map((item) =>
                <ListItem key={item.id} button onClick={() => onClick({ user_to: item.delegate.user.id })}>
                    <ListItemIcon>
                        <Avatar alt={item.user} src={item.logo} />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography className={classes.title}>
                            {item.company_name}
                        </Typography>
                        <Typography className={classes.info}>
                            {item.city}, {item.country}
                        </Typography>
                        <Typography className={classes.info}>
                            {item.web_site}
                        </Typography>
                    </ListItemText>
                </ListItem>
            )}
        </List>
    );
};

export default ChatFormComponent;
