import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'
import FooterLayout from '../layout/Footer.layout'

export function PrivateRoute({
    component: Component,
    render,
    children,
    ...rest
}) {

    const { token } = useSelector(state => state.user)
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])



    return (
        <Route
            {...rest}
            render={() => {
                if (!token) {
                    return <Redirect to='/' />
                }

                return (
                    <Fragment>
                        {children}
                        <FooterLayout />
                    </Fragment>
                )
            }}
        />
    )
}


export default PrivateRoute