import React, { Fragment, useMemo } from "react";
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { Grid } from "@material-ui/core";
import AccomodationCardComponent from '../../components/cards/AccomodationCard.component'
import _ from "lodash";
import LoadingComponent from "../../components/common/Loading.component";
import PaginationLayout from "../../layout/Pagination.layout";


const TourismSellersContainer = ({
	filter
}) => {

	// eslint-disable-next-line
	const { loading, data } = useSWRRequest(Requests.tourism.sellers)

	const dataFilter = useMemo(() => {

		if (filter) {
			let newData = data

			Object.keys(filter).forEach((key) => {

				if (filter[key].length > 0) {

					switch (key) {

						case 'area':
							newData = newData.filter((item) => _.includes(filter[key], item.area.id))
							break;

						case 'categories':
							newData = newData.filter((item) => _.includes(filter[key], item.category.id))
							break;

						case 'services':
							newData = newData.filter((item) => _.difference(filter[key], item.services.map((item) => item.id)).length < filter[key].length)
							break;

						case 'sectors':
							newData = newData.filter((item) => _.difference(filter[key], item.sectors.map((item) => item.id)).length < filter[key].length)
							break;

						case 'chamber_commerce':
							newData = newData.filter((item) => _.includes(filter[key], item.chamber_commerce.id))
							break

						default:
							break;
					}
				}

			})

			return newData
		}

		return data

	}, [filter, data])



	return (
		<PaginationLayout data={dataFilter}>
			{({ page, slice }) =>
				<Fragment>
					{loading && <LoadingComponent />}
					{dataFilter.slice(page * slice, page * slice + slice).map((item) => (
						<Grid item xs={12} sm={6} md={4} key={item.id}>
							<AccomodationCardComponent
								name={item.company_name}
								location={item.city}
								category={item.category}
								productId={item.id}
								image={item.logo} />
						</Grid>
					))}
				</Fragment>
			}
		</PaginationLayout>
	)
}

export default TourismSellersContainer