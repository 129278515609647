import React, { Fragment } from "react";
import HeaderContainer from "../container/header/Header.container";
import { useParams } from "react-router";
import FoodSellerContainer from "../container/food/FoodSeller.container";
import FoodProductsSellerContainer from "../container/food/FoodProductsSeller.container";
import FoodSellerDetailContainer from "../container/food/FoodSellerDetail.container";

const FoodSellerPage = () => {

    const { id } = useParams();

    return (
        <Fragment>
            <HeaderContainer />  
            <FoodSellerDetailContainer seller={id} />          
            <FoodSellerContainer seller={id} />
            <FoodProductsSellerContainer seller={id} />
        </Fragment>
    );
};

export default FoodSellerPage;