import React, { useCallback } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Fragment } from 'react';
import { login } from '../../store/user.store';
import LoginFormComponent from '../../components/form/LoginForm.component';
import * as Yup from 'yup';
import { Redirect } from 'react-router';

export const LoginInitialValues = {
    username: '',
    password: '',    
}

export const LoginValidationSchema = {
    schema: Yup.object().shape({
        username: Yup.string().required('Campo obbligatorio'),
        password: Yup.string().required('Campo obbligatorio'),        
    }),    
}


const LoginContainer = () => {

    const { loading, error, token } = useSelector(state => state.user)
    const dispatch = useDispatch()

    const onSubmitLogin = useCallback((values, actions) => {
        actions.setSubmitting(true)        
        dispatch(login(values))
    }, [])
    
    return (
        <Fragment>
            {token? <Redirect to='/dashboard' /> : null}
            <Formik
                validationSchema={LoginValidationSchema.schema}
                onSubmit={onSubmitLogin}
                initialValues={LoginInitialValues}>
                {(props) => (
                    <Form>
                        {/* { error && <ErrorModal errorText={error?.error_description} />}
                        { loading && <Grid item xs={12}><Loading /></Grid>} */}                        
                        <LoginFormComponent {...props} />
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default LoginContainer;