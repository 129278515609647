import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../store/user.store';
import HeaderComponent from "../../components/header/Header.component";

const HeaderContainer = () => {

    const { token } = useSelector(state => state.user)
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()

    const logoutAction = useCallback(() => {
        dispatch(logout())
    }, [])

    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [open]);


    return (        
        <HeaderComponent
            logoutAction={logoutAction}
            handleClick={handleClick}
            isAuth={token ? true : false}
            open={open} />
    );
};

export default HeaderContainer;
