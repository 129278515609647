export const companyInfo =
{
  registered_street: "21, Sallustio Square",
  headquarter_street: '82, Lucana St.',
  registered_city: '00187 Rome',
  headquarter_city: "75100 Matera",
  // phone: '+39 333 123 4567',
  email: 'info@mirabilianetwork.eu',
  vat: '97963600586'
}



export const footer = {
  id: 1,
  published_at: "2021-08-31T16:29:01.923Z",
  created_at: "2021-08-31T16:28:59.696Z",
  updated_at: "2021-09-01T07:51:02.189Z",
  contact: {
    id: 1,
    address: "Piazza Sallustio 21",
    city: "00187 Roma",
    mobile: "+39 333 123 456",
    email: "info@mirabilianetwork.eu",
    piva: "P.iva 97963600586",
  },
  menu_1: [
    { id: 1, link: "#", name: "Voce Menu 1" },
    { id: 2, link: "#", name: "Voce Menu 2" },
    { id: 3, link: "#", name: "Voce Menu 3" },
    { id: 4, link: "#", name: "Voce Menu 4" },
    { id: 5, link: "#", name: "Voce Menu 5" },
  ],
  menu_2: [
    { id: 6, link: "#", name: "Voce Menu 6" },
    { id: 7, link: "#", name: "Voce Menu 7" },
    { id: 8, link: "#", name: "Voce Menu 8" },
    { id: 9, link: "#", name: "Voce Menu 9" },
    { id: 10, link: "#", name: "Voce Menu 10" },
  ],
};
