import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'transparent',
    },
    title: {
        fontFamily: "LiberGrotesque-Bold",
        color: "#796D57",
        fontSize: "2rem",
    },
    subtitle: {
        fontFamily: "LiberGrotesque-SemiBold",
        color: "#796D57",
    },
});

const ChatTitleComponent = () => {

    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.title}>Chat</Typography>
                <Typography className={classes.subtitle}>                    
                </Typography>
            </Grid>
        </Grid>

    );
};

export default ChatTitleComponent;
