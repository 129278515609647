import React, { Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge'
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash'

const useStyles = makeStyles({
    borderRight500: {
        borderRight: '1px solid #e0e0e0',
        overflowY: 'scroll',
        height: '100%'
    },
    title: {
        fontFamily: "LiberGrotesque-Bold",
        color: "#796D57",
        fontSize: "1rem",
    },
});




const ChatListComponent = ({
    data = [],
    setSelectedChatId
}) => {

    const classes = useStyles();
    const { user } = useSelector(state => state.user)

    const sortedData = useMemo(() => {
        return data.sort(function (a, b) {
            return new Date(b.last_update) - new Date(a.last_update);
        });
    }, [data])


    return (
        <Grid item xs={4} className={classes.borderRight500}>
            {/* <Grid item xs={12} style={{ padding: '10px' }}>
                <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
            </Grid>
            <Divider /> */}
            <List className={classes.list}>
                {sortedData.map((item) =>
                    <Fragment key={item.id}>
                        <ListItem button onClick={() => setSelectedChatId(item.id)}>
                            <ListItemIcon>
                                <Avatar alt={item.user} src={_.replace(item.user_from.id === user.id ? item.user_to?.company?.logo : item.user_from?.company?.logo, 'open', 'uc')} />                                
                            </ListItemIcon>
                            <ListItemText>
                                <Typography className={classes.title}>
                                    {item.user_from.id === user.id ? item.user_to?.company?.company_name : item.user_from?.company?.company_name}
                                </Typography>
                            </ListItemText>
                            <ListItemText align="right">
                                {item.unread > 0 && <Badge badgeContent={item.unread} color="error" />}
                            </ListItemText>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </Fragment>
                )}
            </List>
        </Grid>
    );
}

export default ChatListComponent;