import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReceiptRoundedIcon from "@material-ui/icons/ReceiptRounded";
import {
	Divider,
	Grid,
	Card,
	Box,
	CardContent,
	Typography,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		maxWidth: 300,
		minHeight: 300,
		backgroundColor: "#796d57",
		color: "#fff",
		borderRadius: "1rem",
		padding: "1rem",
	},
	titleContainer: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "1rem",
	},
	title: {
		fontFamily: "LiberGrotesque-Bold",
		fontSize: "1.375rem",
		marginLeft: "0.8rem",
	},
	text: {
		fontFamily: "LiberGrotesque-Bold",
		color: "#fff",
	},
	detail: {
		fontFamily: "LiberGrotesque-Bold",		
		color: "#fff",
	},
	divider: {
		backgroundColor: "#fff",
		margin: "0.5rem 0rem",
	},
	list: {
		listStyleType: "none",
		margin: 0,
		padding: 0,
	},
	data: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
});
const DetailCardComponent = ({
	details = []
}) => {

	const classes = useStyles();
	const { t } = useTranslation()

	return (
		<Card className={classes.root}>
			<CardContent>
				<Box className={classes.titleContainer}>
					<ReceiptRoundedIcon />
					<Typography className={classes.title} gutterBottom>
						{t('food.product.data_sheet')}
					</Typography>
				</Box>
				<Grid container>
					<Grid item xs={12}>
						{details.map((detail, index) => (
							<Fragment key={index}>
								<Typography className={classes.text} variant='subtitle1'>
									{detail.category}
								</Typography>
								<Typography className={classes.detail} variant='subtitle2'>
									{detail.description}
								</Typography>
								<Divider className={classes.divider} />
							</Fragment>
						))}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default DetailCardComponent;
