import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";
import { Provider } from 'react-redux'
import './App.css';
import store from "./store/app.store";
import Endpoints from './core/endpoints'
import MainLayout from "./layout/Main.layout";
Endpoints.baseURL = process.env.REACT_APP_BASE_URL

function App() {
	return (
		<Suspense fallback="loading">
			<Provider store={store}>
				<MainLayout>
					<BrowserRouter>
						<Router />
					</BrowserRouter>
				</MainLayout>
			</Provider>
		</Suspense>
	);
}

export default App;
