import React, { useState } from 'react'
import { makeStyles, IconButton, Box, withStyles } from '@material-ui/core'
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ListIcon from '@material-ui/icons/List';
import GetAppIcon from '@material-ui/icons/GetApp';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	styleIcon: {
		backgroundColor: 'transparent',
		color: '#796D57',
		borderColor: '#796D57',
		borderRadius: '1rem',
		"& .MuiToggleButton-root.Mui-selected": {
			backgroundColor: 'red',
			"& .MuiToggleButton-root.Mui-selected:hover": {
				backgroundColor: 'red',
			}
		},

	}
})

const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		margin: '1rem',
		borderRadius: '1rem',
		'&:not(:first-child)': {
			borderRadius: '2rem',
			marginLeft: '1rem',
			border: 'solid 2px',
		},
		'&:first-child': {
			borderRadius: '2rem',
			border: 'solid 2px',
		},
	},
}))(ToggleButtonGroup);

const DisplayStyleComponent = ({
	setStyle = () => { },
	setPrint = () => { },
	setSelected = () => { },
}) => {
	const classes = useStyles()

	const [view, setView] = useState('grid');

	const handleAlignment = (event, newView) => {
		setView(newView);
	};
	return (
		<Box className={classes.root}>
			<StyledToggleButtonGroup
				value={view}
				exclusive
				onChange={handleAlignment}
				aria-label="text alignment">
				<ToggleButton className={classes.styleIcon} value={'grid'} aria-label="left aligned" onClick={() => setSelected(0)}>
					<AppsOutlinedIcon />
				</ToggleButton>
				<ToggleButton className={classes.styleIcon} value={'list'} aria-label="right aligned" onClick={() => setSelected(1)}>
					<ListIcon />
				</ToggleButton>
				<IconButton className={classes.styleIcon} value={'list'} aria-label="right aligned" onClick={() => setPrint(true)}>
					<GetAppIcon />
				</IconButton>
			</StyledToggleButtonGroup>
		</Box>
	)
}

export default DisplayStyleComponent