import _ from 'lodash'

export const AgrifoodEndpoints = {
    basePath: '/api/v1/agrifood/',
    endpoints: {
        categories: {
            path: '/categories/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: _.replace(item.name, new RegExp('^[^-]*-'), ''),
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        other_categories: {
            path: '/buyers/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                let result = []
                data.forEach(item => {
                    const other = item.other_categories.split(';')
                    result = [...result, ...other]
                });
                result = _.union(result).map((item, index) => ({
                    label: item,
                    id: item
                }))
                return result.sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        activities: {
            path: '/activities/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        chambers_commerce: {
            path: '/chambers-commerce/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        areas: {
            path: '/areas/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        products: {
            path: '/products/',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                ...item,
                category: {
                    ...item.category,
                    name: _.replace(item.category.name, new RegExp('^[^-]*-'), '')
                }
            }))
        },
        product: {
            path: '/products/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            transform: (item) => ({
                ...item,
                category: {
                    ...item.category,
                    name: _.replace(item.category.name, new RegExp('^[^-]*-'), '')
                }
            }),
            swr: {
                revalidateOnFocus: false
            }
        },
        productsSeller: {
            path: '/products?seller={{seller}}',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => data.map((item) => ({
                ...item,
                category: {
                    ...item.category,
                    name: _.replace(item.category.name, new RegExp('^[^-]*-'), '')
                }
            }))
        },
        packages: {
            path: '/packages/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        producing_capacities: {
            path: '/producing-capacities/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        shelf_lifes: {
            path: '/shelf-lifes/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        buyers: {
            path: '/buyers/',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false
            }
        },
        sellers: {
            path: '/sellers/',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false
            }
        },
        seller: {
            path: '/sellers/{{seller}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            swr: {
                revalidateOnFocus: false
            }
        },
    }
}

export default AgrifoodEndpoints;