import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NetworkImg from "../assets/img/network.jpg";
import HeaderContainer from "../container/header/Header.container";
import { useTranslation } from 'react-i18next';
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    text: {
        fontFamily: "LiberGrotesque-SemiBold",
    },
    link: {
        fontFamily: "LiberGrotesque-SemiBold",
        textDecoration: "underline",
        cursor: 'pointer'
    }
}));

const ContactsPage = () => {

    const classes = useStyles();
    const { t } = useTranslation()

    const onClickUrl = useCallback((link) => {
        window.open(link, '_blank')
    }, [])

    return (

        <Fragment>
            <HeaderContainer />
            <CatalogueHeaderComponent img={NetworkImg} title={t("contacts.title")} />
            <Container maxWidth={false}>
                <Box mt={2}>
                    <Typography className={classes.text} variant='h6'>
                        {t('contacts.site')} 
                    </Typography>
                    <Typography className={classes.text} variant='subtitle1'>
                        c/o Unioncamere
                    </Typography>
                    <Typography className={classes.text} variant='subtitle1'>
                        Piazza Sallustio n. 21
                    </Typography>
                    <Typography className={classes.text} variant='subtitle1'>
                        00187 – Roma
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography className={classes.text} variant='subtitle1'>
                        {t('contacts.email')}
                    </Typography>
                </Box>
                <Box mt={2} mb={2}>
                    <Typography className={classes.link} variant='subtitle1'>
                        <a href="mailto:mirabiliab2bplatform@gmail.com">mirabiliab2bplatform@gmail.com</a> 
                    </Typography>
                </Box>

            </Container>
        </Fragment>
    );
}

export default ContactsPage
