import { Box } from "@material-ui/core";
import TerritoryCard from "../cards/TerritoryCard.component";
import { useTranslation } from 'react-i18next';

const TerritoryCarouselComponent = ({
	territories = []
}) => {

	const { t } = useTranslation()

	return (
		<Box display='flex' overflow='scroll'>
			{territories.map((card, index) => (
				<TerritoryCard
					key={index}
					image={card} />
			))}
		</Box>
	);
};

export default TerritoryCarouselComponent;
