import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import classes from './footer.module.css'
import cx from 'classnames'

const CookieComponent = () => {
  return (
    <Grid container className={classes.cookieWrapper}>
      <Grid item xs={12} md={8}>
        <Typography className={cx(classes.cookieText, classes.link)}>credits: mentefredda e abinsula</Typography>
      </Grid>
      {/* <Grid item xs={3} md={2}>
        <Typography className={classes.cookieText}> <a href="www.google.com" className={classes.link}>
          cookie privacy
        </a></Typography>
      </Grid>
      <Grid item xs={3} md={2}>
        <Typography className={classes.cookieText}><a href="www.google.com" className={classes.link}>
          privacy policy
        </a></Typography>
      </Grid> */}
    </Grid>
  )
}

export default CookieComponent