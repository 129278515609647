export const TourismEndpoints = {
    basePath: '/api/v1/tourism/',
    endpoints: {
        sellers: {
            path: '/sellers/',
            method: 'GET',
            get: 'data',
            fallback: [],
            swr: {
                revalidateOnFocus: false
            }
        },
        seller: {
            path: '/sellers/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: null,
            swr: {
                revalidateOnFocus: false
            }
        },
        buyers: {
            path: '/buyers/',
            method: 'GET',
            get: 'data',            
            fallback: [],
            swr: {
                revalidateOnFocus: false
            }
        },
        buyer: {
            path: '/buyers/{{id}}',
            method: 'GET',
            get: 'data',
            fallback: null,
            swr: {
                revalidateOnFocus: false
            }
        },
        areas: {
            path: '/areas/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        chambers_commerce: {
            path: '/chambers-commerce/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        categories: {
            path: '/categories/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        sectors: {
            path: '/sectors/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        },
        services: {
            path: '/services/',
            method: 'GET',
            get: 'data',
            fallback: [],
            transform: (data) => {
                return data.map((item) => ({
                    label: item.name,
                    ...item
                })).sort((a, b) => a.label.localeCompare(b.label))
            },
            swr: {
                revalidateOnFocus: false
            }
        }
    }
}

export default TourismEndpoints;