import React, { useState, useCallback, useEffect } from "react";
import { AppBar, Toolbar, Box, Grid } from "@material-ui/core";
import IconButtonComponent from "../buttons/IconButton.component";
import DialogComponent from "./components/Dialog.component";
import classes from "./header.module.css";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation } from "react-router-dom";
import LogoComponent from "../logo/logo.component";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import cx from "classnames";
import { useHistory } from "react-router";

const styleHome = {
    boxShadow: "none",
    minHeight: "71px",
    paddingTop: "0.7rem",
};
const style = {};

const languages = [
    {
        code: "it",
        title: "header.languages.it",
    },
    {
        code: "en",
        title: "header.languages.en",
    },
];

const HeaderComponent = ({
    open = false,
    isAuth = false,
    logoutAction = () => { },
    handleClick = () => { },
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileView, setMobileView] = useState(false);
    const { pathname } = useLocation();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onChangeLanguage = useCallback((language) => {
        i18n.changeLanguage(language);
    }, []);

    const handleLogoutAction = useCallback(() => {
        history.push("/");
        logoutAction();
        setAnchorEl(null);
    }, []);

    const handleLogin = useCallback(() => {
        history.push("/login");
    }, []);

    useEffect(() => {
        const setResponsiveness = () => setMobileView(window.innerWidth < 768);
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/calendar" className={classes.link}>
                    {t("header.calendar")}
                </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/profile" className={classes.link}>
                    {t("header.profile")}
                </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
                <Link to="/dashboard/chat" className={classes.link}>
                    {t("header.chat")}
                </Link>
            </MenuItem>
            <MenuItem onClick={handleLogoutAction}>
                <Link to="/dashboard/profile" className={classes.link}>
                    {t("header.logout")}
                </Link>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.container}>
            <AppBar
                position="static"
                color={pathname === "/" ? "transparent" : "#fff"}
                style={pathname === "/" ? styleHome : style}
            >
                <Toolbar>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Grid item xs={3}>
                            <IconButtonComponent
                                title={t("menu.title")}
                                icon={<MenuIcon />}
                                backgroundColor="#796D57"
                                onClick={handleClick}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            {pathname !== "/" && (
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        className={classes.box}
                                    >
                                        <LogoComponent src="/static/logo.png" />
                                    </Box>
                                </Link>
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            <Box
                                className={classes.languages}
                                display="flex"
                                justifyContent={mobileView != true ? "flex-end" : "center"}
                                alignItems="center"
                            >
                                {mobileView != true
                                    ? languages.map((language) => (
                                        <Box
                                            key={language.code}
                                            className={cx(
                                                classes.language,
                                                pathname === "/" ? classes.white : classes.secondary
                                            )}
                                            onClick={() => onChangeLanguage(language.code)}
                                        >
                                            {t(language.title)}
                                        </Box>
                                    ))
                                    : null}
                                {isAuth ? (
                                    <Box>
                                        <IconButton
                                            edge="end"
                                            onClick={handleProfileMenuOpen}
                                            // className={
                                            //     pathname === "/" ? classes.white : classes.secondary
                                            // }
                                            style={{ backgroundColor: '#b91818' }}
                                        >
                                            <AccountCircle style={{ color: '#fff' }} />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Box>
                                        <IconButtonComponent
                                            title={"Login"}
                                            backgroundColor="#b91818"
                                            onClick={handleLogin}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <DialogComponent
                languages={languages}
                onChangeLanguage={onChangeLanguage}
                open={open}
                handleClose={handleClick}
            />
        </div >
    );
};

export default HeaderComponent;
