import React from 'react'
import MinisteroLogo from '../../assets/img/Ministero_logo.svg'
import ItaLogo from '../../assets/img/ITA.svg'
import UnionLogo from '../../assets/img/unioncamere.png'
import IegLogo from '../../assets/img/IEG.svg'
import AssoCameraITLogo from '../../assets/img/AssocameresteroIT.svg'
import AssoCameraENLogo from '../../assets/img/AssocameresteroEN.svg'
import classes from './footer.module.css'
import { Grid, Container, Box } from '@material-ui/core'

const PartnerComponent = () => {
	return (
		<Container maxWidth={false}>
			<Box p={4}>
				<Grid spacing={2}>
					<Grid container>
						<Grid item xs={12} sm={6} md={4} className={classes.item}>
							<img className={classes.imagePartner} src={MinisteroLogo} alt={''} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} className={classes.item}>
							<img className={classes.imagePartner} src={ItaLogo} alt={''} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} className={classes.item}>
							<img className={classes.imagePartner} src={UnionLogo} alt={''} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} className={classes.item}>
							<img className={classes.imagePartner} src={IegLogo} alt={''} />
						</Grid>
						<Grid item xs={12} sm={6} md={4} className={classes.item}>
							<img className={classes.imagePartner} src={AssoCameraITLogo} alt={''} />
						</Grid>
						{/* <Grid item xs={12} sm={6} className={classes.item}>
							<img className={classes.imagePartner} src={AssoCameraENLogo} alt={''} />
						</Grid> */}
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default PartnerComponent