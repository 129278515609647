import React, { Fragment, useMemo } from "react";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { Grid } from "@material-ui/core";
import LoadingComponent from "../../components/common/Loading.component";
import PaginationLayout from "../../layout/Pagination.layout";
import _ from 'lodash'
import BuyerFoodCardComponent from "../../components/cards/BuyerFoodCard.component";

const FoodBuyersContainer = ({
	filter
}) => {

    const { loading, data } = useSWRRequest(Requests.agrifood.buyers);

    const dataFilter = useMemo(() => {

		if (filter) {
			let newData = data

			Object.keys(filter).forEach((key) => {

				if (filter[key].length > 0) {

					switch (key) {

						case 'categories':                        
                            newData = newData.filter((item) => item.other_categories.split(';').some((r) => _.includes(filter[key], r)))
							break;

						case 'activities':
                            newData = newData.filter((item) => item.activities.some((r) => _.includes(filter[key], r.id)))
							break;						

						default:
							break;
					}
				}

			})

			return newData
		}

		return data

	}, [filter, data])
    
    return (
        <PaginationLayout data={dataFilter}>
            {({ page, slice }) => (
                <Fragment>
                    <Grid item xs={12}>
						{loading && <LoadingComponent />}
					</Grid>	
                    {dataFilter.slice(page * slice, page * slice + slice).map((item) => (
                        <Grid item xsw={12} sm={6} key={item.id}>
                            <BuyerFoodCardComponent item={item}/>
                        </Grid>
                    ))}
                </Fragment>
            )}
        </PaginationLayout>
    );
};

export default FoodBuyersContainer;
