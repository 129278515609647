import React, { Fragment } from 'react';
import CookieComponent from '../components/footer/Cookie.component';
import FooterComponent from '../components/footer/Footer.component';
import PartnerComponent from '../components/footer/Partner.component';
import NetworkComponent from '../components/footer/Network.component';

const FooterLayout = () => {
  return (
    <Fragment>
      <NetworkComponent />
      <PartnerComponent />
      <FooterComponent />
      <CookieComponent />
    </Fragment>
  );
}

export default FooterLayout;