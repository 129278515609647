import React, { Fragment } from "react";
import { Box, Grid, Typography, Container } from "@material-ui/core";
import HeaderContainer from "../container/header/Header.container";
import HomeImg from "../assets/img/food-cooking-background.jpg";
import TopImageLayout from "../layout/TopImg.layout";
import TitleComponent from "../components/title/Title.component";
import { CategoryCards } from "../config/CategoryCards.config";
import CategoryCard from "../components/cards/CategoryCard.component";
import LogoComponent from "../components/logo/logo.component";
import classes from "./styles/home.module.css";
import { Link } from "react-router-dom";
import TerritoryContainer from "../container/home/Territory.container";
import { useTranslation } from 'react-i18next';
import SearchHomeComponent from "../components/search/SearchHome.component";

const HomePage = () => {

	const { t } = useTranslation()

	return (
		<Fragment>

			<TopImageLayout image={HomeImg}>
				<Container maxWidth={false}>
					<HeaderContainer />

					<Box width={1} justifyContent='center' display='flex'>
						<LogoComponent src="/static/logo_mirabilia.svg" height="112px" width="210px" />
					</Box>

					<Box width={1} marginTop={8}>
						<TitleComponent />
					</Box>
				</Container>
			</TopImageLayout>

			<Box className={classes.categoryHome}>
				<Container maxWidth={false}>
					<Grid container style={{ position: "relative", bottom: "4rem" }} justifyContent="center">
						{CategoryCards.map((card) => (
							<Grid item justifyContent="center" xs={12} md={4} style={{ display: "flex", flexDirection: "row" }}>
								<Link to={card.link} key={card.link} style={{ textDecoration: 'none' }}>
									<CategoryCard
										title={card.title}
										subtitle={card.subtitle}
										buttonTitle={card.buttonTitle}
										image={card.image}
									/>
								</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>



			<Box className={classes.networkHome}>
				<Container maxWidth={false}>
					<Grid container>
						<Grid item xs={12} md={8}>
							<Box className={classes.textHome}>
								<Box className={classes.titleWrapper}>
									<Typography
										style={{
											fontFamily: "LiberGrotesque-Bold",
											fontSize: "2rem",
											color: "#796D57",
										}}>
										{t('home.network.title_one')},
									</Typography>
									<Typography
										style={{
											fontFamily: "LiberGrotesque-Regular",
											fontSize: "2rem",
											color: "#796D57",
										}}>
										{t('home.network.title_two')}
										<span
											style={{
												color: "#B91818",
												fontWeight: "400",
												fontFamily: "LiberGrotesque-Regular",
											}}>
											{" "}
											{t('home.network.title_three')}
										</span>
									</Typography>
								</Box>
								<Typography
									variant="body1"
									style={{
										fontWeight: "600",
										color: "#796D57",
										fontFamily: "LiberGrotesque-Semibold",
									}}>
									{t('home.network.subtitle')}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<TerritoryContainer />
				</Container>
			</Box>
		</Fragment>
	);
};

export default HomePage;
