import React, { Fragment } from 'react';

const MainLayout = ({
	children
}) => {
	return (
		<Fragment>
			<main>
				{children}
			</main>
		</Fragment>
	);
}

export default MainLayout;