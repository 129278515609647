import React, { Fragment, useMemo } from "react";
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { Grid } from '@material-ui/core'
import _ from "lodash";
import LoadingComponent from "../../components/common/Loading.component";
import PaginationLayout from "../../layout/Pagination.layout";
import BuyerTourismCardComponent from "../../components/cards/BuyerTourismCard.component";


const TourismBuyersContainer = ({
	filter
}) => {

    const { loading, data } = useSWRRequest(Requests.tourism.buyers)

    const dataFilter = useMemo(() => {

		if (filter) {
			let newData = data

			Object.keys(filter).forEach((key) => {

				if (filter[key].length > 0) {

					switch (key) {
						
						case 'categories':
							newData = newData.filter((item) => _.includes(filter[key], item.category.id))
							break;

						case 'services':
							newData = newData.filter((item) => _.difference(filter[key], item.services.map((item) => item.id)).length < filter[key].length)
							break;

						case 'sectors':
							newData = newData.filter((item) => _.difference(filter[key], item.sectors.map((item) => item.id)).length < filter[key].length)
							break;

						default:
							break;
					}
				}

			})

			return newData
		}

		return data

	}, [filter, data])

    return (
        <PaginationLayout data={dataFilter}>
            {({ page, slice }) =>
                <Fragment>
                    {loading && <LoadingComponent />}
                    {dataFilter.slice(page * slice, page * slice + slice).map((item) => (
                        <Grid item xs={12} sm={6} key={item.id}>
                            <BuyerTourismCardComponent item={item}/>
                        </Grid>
                    ))}
                </Fragment>
            }
        </PaginationLayout>
    )
}

export default TourismBuyersContainer