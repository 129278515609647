import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import MenuComponent from "../components/private/MenuComponent";
import HeaderContainer from "../container/header/Header.container";

const DashboardPage = () => {

    const { token } = useSelector(state => state.user)

    return (
        <Fragment>

            {!token? <Redirect to='/' /> : null}
            <HeaderContainer />
            <MenuComponent />                        
        </Fragment>
    );
};

export default DashboardPage;