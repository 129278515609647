import React from 'react'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: props => ({
    color: props.colorText,
    padding: '1rem',
    borderRadius: '5rem',
    backgroundColor: props.backgroundColor,
    '&:hover': {
      backgroundColor: props.backgroundColor,
    },
    height: props.height,
    width: props.width,
    fontSize: props.fontSize,
    border: props.border,
    alignItems: 'center',
    textTransform: props.textTransform,
    fontFamily: 'LiberGrotesque-Bold',
  }),
}));

const noop = () => { }
const IconButtonComponent = ({
  title,
  icon,
  onClick = noop,
  backgroundColor = '#fff',
  colorText = '#fff',
  border,
  height = '2.5rem',
  width = 'auto',
  fontSize = '1rem',
  textTransform = 'capitalize'
}) => {

  const classes = useStyles({
    backgroundColor,
    colorText,
    border,
    height,
    width,
    fontSize,
    textTransform
  });

  return (
    <Button
      onClick={onClick}
      className={classes.button}
      startIcon={icon}
    >
      {title}
    </Button>

  )
}

export default IconButtonComponent