import React from "react";
import ProductCardComponent from "../cards/ProductCard.component";
import { Box } from "@material-ui/core";

const ProductCarouselComponent = ({
	items = []
}) => {

	return (
		<Box display='flex' overflowX='scroll'>
			{items.map((item) => (
				<Box m={2} key={item.id}>
					<ProductCardComponent						
						productName={item.name}
						category={item.category}
						image={item.image}
						productId={item.id}
					/>
				</Box>
			))}
		</Box>
	);
};

export default ProductCarouselComponent;
