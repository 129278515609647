import _ from 'lodash'

import Client from '../manager'
import Endpoints from '../endpoints'

const interpolateRegex = /\{\{(\S+?)\}\}/g
const getGroup = index => match => match[index]

export default Object.entries(Endpoints).reduce((acc, [sectionKey, value]) => {
    const { basePath = '', endpoints, defaults, ...defaultOptions } = value
    const requestHandlers = Object.entries(endpoints).reduce((acc, [endpointKey, value]) => {
        const settings = { ...defaults, ...value }
        const { basePath: endpointBasePath = basePath, path = '', method = 'GET', get, fallback, transform, swr, auth = false, ...options } = settings
        const [paramsTemplate = '', queryTemplate = ''] = path.split('?')
        const paramsKeys = Array.from(paramsTemplate.matchAll(interpolateRegex), getGroup(1))
        const queryParamsKeys = Array.from(queryTemplate.matchAll(interpolateRegex), getGroup(1))
        const paramsInterpolator = _.template(paramsTemplate, { interpolate: interpolateRegex })
        const queryInterpolator = _.template(queryTemplate, { interpolate: interpolateRegex })
        const pathAndQueryParams = [...paramsKeys, ...queryParamsKeys]
        const hasParams = pathAndQueryParams.length > 0
        const canHaveBody = ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase())

        const requestHandler = (...args) => {
            
            const { baseURL = '' } = Endpoints
            const APIBaseURL = [
                ...baseURL.split('/'),
                ...endpointBasePath.split('/').filter(Boolean),
            ].join('/')

            let params, body, token, headers = {}

            if (hasParams) {
                params = args[0]
                body = args[1]
                if (auth) {
                    token = params.token
                    headers['Authorization'] = `Bearer ${token}`
                    params = _.omit(params, 'token')
                }
            }


            if (!hasParams && canHaveBody) {
                body = args[0]
                if (auth) {
                    token = body.token
                    headers['Authorization'] = `Bearer ${token}`
                    body = _.omit(body, 'token')
                }
            }

            if (!hasParams && !canHaveBody) {
                if (auth) {
                    token = args[0].token
                    headers['Authorization'] = `Bearer ${token}`
                }
            }

            if (canHaveBody && options.multipart) {

                let formData = new FormData()

                Object.keys(body).forEach(key => {
                    formData.append(key, body[key])
                });
                body = formData
                headers['Content-Type'] = 'multipart/form-data'
            }

            const interpolatedPath = paramsInterpolator(params)
            const interpolatedQuery = queryInterpolator(params)            

            return Client({
                baseURL: APIBaseURL,
                method,
                data: body,
                url: interpolatedPath,
                params: interpolatedQuery,
                headers,
                paramsSerializer: function paramsSerializer() {
                    return interpolatedQuery
                },
                ...defaultOptions, ...options,
            }).then(response => {

                if (typeof response.data === 'undefined') {
                    return fallback
                }

                return [
                    get && function (data) {
                        if (get) {
                            return _.get(data, get, fallback)
                        }
                        return data
                    },
                    transform instanceof Function && function (data) {
                        return transform(data, response, settings)
                    }
                ]
                    .filter(Boolean)
                    .reduce((data, fn) => fn(data), response.data)
            })
        }

        requestHandler.settings = settings

        requestHandler.paramsKeys = paramsKeys
        requestHandler.queryParamsKeys = queryParamsKeys
        requestHandler.pathAndQueryParams = pathAndQueryParams

        requestHandler.swrOptions = swr

        requestHandler.getKey = (params) => {
            const interpolatedPath = paramsInterpolator(params)
            const interpolatedQuery = queryInterpolator(params)
            return `${sectionKey}::${endpointKey}::${interpolatedPath}::${interpolatedQuery}`
        }

        return { ...acc, [endpointKey]: requestHandler }
    }, {})
    return { ...acc, [sectionKey]: requestHandlers }
}, {})
