import React, { useState, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, Box } from '@material-ui/core';
import ChatMessageContainer from './ChatMessage.container';
import ChatTitleComponent from '../../components/chat/ChatTitle.component';
import ChatListContainer from './ChatList.container';
import CreateChatComponent from "../../components/chat/CreateChat.component";
import ModalComponent from '../../components/common/Modal.component';
import ChatAddContainer from './ChatAdd.container';


const ChatContainer = () => {

    const [selectedChatId, setSelectedChatId] = useState(null)
    const [openAdd, setOpenAdd] = useState(false)

    const onSetOpenAdd = useCallback(() => {
        setOpenAdd(!openAdd)
    }, [openAdd])

    

    const selectChat = useCallback((id) => {
        console.log('selectChat')
        setSelectedChatId(id)
    }, [])

    return (
        <Box style={{ padding: '1.5rem' }}>
            <ChatTitleComponent />
            <CreateChatComponent setOpenAdd={setOpenAdd} />
            <ModalComponent
                title='Chat'
                onClose={onSetOpenAdd}
                open={openAdd}
                fullWidth={true}
                maxWidth='sm'>
                <ChatAddContainer onClose={onSetOpenAdd} />
            </ModalComponent>
            <Grid container component={Paper} style={{ height: '80vh' }}>
                <ChatListContainer setSelectedChatId={selectChat} />
                {selectedChatId && <ChatMessageContainer selectedChatId={selectedChatId} />}
            </Grid>
        </Box>
    );
}

export default ChatContainer;