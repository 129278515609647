import React, { Fragment, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import FooterLayout from '../layout/Footer.layout'
//import { useSelector } from 'react-redux'

export function PublicRoute({
    component: Component,
    render,
    children,
    ...rest
}) {

    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    //const { token } = useSelector(state => state.user)

    return (
        <Route
            {...rest}
            render={() => {
                // if (token) {
                //     return <Redirect to='/user/tender_notice_list' />
                // }

                return (
                    <Fragment>
                        {children}
                        <FooterLayout />
                    </Fragment>)
            }}
        />
    )
}


export default PublicRoute