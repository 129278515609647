import { Grid, Box } from '@material-ui/core'
import React, { Fragment } from 'react'
import HeaderContainer from "../container/header/Header.container";
import LoginImg from '../assets/img/login.jpg'
import classes from './styles/login.module.css'
import LoginContainer from '../container/login/Login.container'

const LoginPage = () => {
	return (
		<Fragment>
			<HeaderContainer />
			<Grid container alignItems='center'>
				<Grid item xs={12} sm={6}>
					<Box className={classes.loginWrapper}>
						<LoginContainer />
					</Box>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box className={classes.imageWrapper}>
						<img src={LoginImg} />
					</Box>
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default LoginPage