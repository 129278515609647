import React, { Fragment, useEffect } from 'react';
import ChatListComponent from '../../components/chat/ChatList.component';
import LoadingComponent from "../../components/common/Loading.component";
import Requests from '../../core/requests';
import { useSelector } from 'react-redux';
import useSWRRequest from '../../core/useSWRRequest';

const ChatListContainer = ({
    setSelectedChatId
}) => {

    const { token } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.conversations.listChats, { token: token.access });

    return (
        <Fragment>
            {loading && <LoadingComponent />}
            <ChatListComponent data={data} setSelectedChatId={setSelectedChatId} />
        </Fragment>
    );
}

export default ChatListContainer;