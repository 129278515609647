import React, { Fragment, useMemo } from "react";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { Grid } from "@material-ui/core";
import SellerCardComponent from "../../components/cards/SellerCard.component";
import LoadingComponent from "../../components/common/Loading.component";
import PaginationLayout from "../../layout/Pagination.layout";
import _ from 'lodash'

const FoodSellersContainer = ({
	filter
}) => {

	const { loading, data } = useSWRRequest(Requests.agrifood.sellers);
	const dataFilter = useMemo(() => {

		if (filter) {
			let newData = data

			Object.keys(filter).forEach((key) => {

				if (filter[key].length > 0) {

					switch (key) {

						case 'area':
							newData = newData.filter((item) => _.includes(filter[key], item.area.id))
							break;

						case 'chamber_commerce':
							newData = newData.filter((item) => _.includes(filter[key], item.chamber_commerce.id))
							break;

						default:
							break;
					}
				}

			})

			return newData
		}

		return data

	}, [filter, data])

	return (
		<PaginationLayout data={dataFilter}>
			{({ page, slice }) => (
				<Fragment>
					<Grid item xs={12}>
						{loading && <LoadingComponent />}
					</Grid>	
					{dataFilter.slice(page * slice, page * slice + slice).map((item) => (
						<Grid item xs={12} sm={6} md={3} key={item.id}>
							<SellerCardComponent
								name={item.company_name}
								chamber_commerce={item.chamber_commerce.name}
								sellerId={item.id}
								image={item.logo}
							/>
						</Grid>
					))}
				</Fragment>
			)}
		</PaginationLayout>
	);
};

export default FoodSellersContainer;
