import React from "react";
import { Box, Grid } from "@material-ui/core";
import classes from "./title.module.css";
import { useTranslation } from 'react-i18next';

const TitleComponent = () => {

    const { t } = useTranslation();


    return (
        <Grid container>
            <Grid item xs={12}>
                <Box className={classes.title}>{t('home.mirabilia')}</Box>
            </Grid>

            <Grid item xs={12}>
                <Box className={classes.title}>{t('home.slogan')}</Box>
            </Grid>
        </Grid>
    );
};

export default TitleComponent;
