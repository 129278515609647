import React from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { Box, makeStyles } from "@material-ui/core";
import ProductCarouselComponent from '../../components/product/ProductCarousel.component'

const useStyles = makeStyles({
    container: {
        padding: '2rem',
        backgroundColor: '#EEE9E2',
        overflowX: 'scroll'
    }
})


const FoodProductsSellerContainer = ({
    seller
}) => {

    const classes = useStyles()
    const { data } = useSWRRequest(Requests.agrifood.productsSeller, { seller })    

    return (
        <Box className={classes.container}>
            <ProductCarouselComponent items={data}/>
        </Box>
    )
}

export default FoodProductsSellerContainer