import React, { useState, useEffect } from "react";
import IconButtonComponent from "../../buttons/IconButton.component";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid, AppBar, Toolbar, Box } from "@material-ui/core";
import classes from "../header.module.css";
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import { useLocation } from "react-router";

const noop = () => { };
const HeaderDialogComponent = ({
    handleClose = noop,
    onChangeLanguage,
    languages
}) => {

    const [mobileView, setMobileView] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation()

    useEffect(() => {
        const setResponsiveness = () => setMobileView(window.innerWidth < 768)
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    return (
        <div className={classes.container}>
            <AppBar position="static"
                style={{
                    backgroundColor: "#ffffff00",
                    minHeight: "71px",
                    paddingTop: '0.7rem',
                    marginTop: mobileView ? "0.75rem" : "",
                }}>
                <Toolbar>
                    <Grid container direction="row" alignItems="center" justifyContent="flex-start">
                        <Grid item xs={4}>
                            <IconButtonComponent title={t("menu.title")} icon={<ClearIcon />} backgroundColor="#796D57" onClick={handleClose} />
                        </Grid>
                        <Grid item xs>
                            <Box className={classes.languages} display='flex' justifyContent='flex-end' alignItems='center'>
                                {languages.map((language) =>
                                    <Box
                                        key={language.code}
                                        className={cx(classes.language, classes.white)}
                                        onClick={() => onChangeLanguage(language.code)}>
                                        {t(language.title)}
                                    </Box>
                                )}
                            </Box>

                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default HeaderDialogComponent;