import React from "react";
import {
    TextField,
    InputAdornment,
    makeStyles,
    colors,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: "2.1rem",
        padding: 0,
        backgroundColor: "RGBA(121, 109, 87, 0.28)",
        borderRadius: "5rem",
        color: "#5C5C5C",
        border: "0px solid",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        color: "#5C5C5C",
        " & .MuiOutlinedInput-inputAdornedStart": {
            fontFamily: "LiberGrotesque-Bold",
        },
        " & .MuiOutlinedInput-input": {
            padding: '4px 0px 0px 1px'
        },
        "& label.Mui-focused": {
            color: "#796D57"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.grey[300]
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#ffffff00",
            },
            "&:hover fieldset": {
                borderColor: "#ffffff00",
            },
            "&.Mui-focused fieldset": {
                borderColor: "transparent",
                borderRadius: "5rem",
            },
        },
    },
    icon: {
        color: "#5C5C5C",
        fontSize: "1.2rem",
    },
}));

const SearchComponent = ({ label, handleChange }) => {
    const classes = useStyles();

    return (
        <TextField
            className={classes.root}
            placeholder={label}
            size="small"
            variant="outlined"
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" className={classes.box}>
                        <SearchIcon className={classes.icon} />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchComponent;
