import React, { Fragment } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box } from '@material-ui/core';
import DetailCardComponent from '../cards/DetailCard.component';
import { ProductDetails } from '../../config/Detail.config';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		padding: '2rem',
		backgroundColor: '#fff',
		alignItems: 'center '
	},
	media: {
		height: '100%',
		minHeight: '400px',
		width: "100%",
		objectFit: 'contain'
	},
	infoContainer: {
		color: '#796d57',
		margin: '1rem',
		padding: '0rem 1rem'
	},
	category: {
		fontFamily: 'LiberGrotesque-Bold',
		color: '#B91818'
	},
	title: {
		fontFamily: 'LiberGrotesque-Bold',
		fontSize: '24px'
	},
	description: {
		fontFamily: 'LiberGrotesque-Bold',
		fontSize: '14px',
		marginTop: '1.5rem'
	},
	extra: {
		fontFamily: 'LiberGrotesque-Bold',
		fontSize: '14px',
		color: '#B91818',
		textTransform: 'uppercase',
		marginTop: '1rem'
	}
})

const ProductDetailComponent = ({
	image,
	category = '',
	title = '',
	description = '',
	ingredients = '',
	details = ProductDetails
}) => {
	const classes = useStyles()
	const { t } = useTranslation();
	return (
		<Grid container className={classes.root}>
			<Grid item xs={12} sm={3} md={2}>
				<img className={classes.media} src={_.replace(image, 'open', 'uc')} />
			</Grid>
			<Grid item xs={12} sm={6} md={6} >
				<Box className={classes.infoContainer}>
					<Typography className={classes.category}>
						{category}
					</Typography>
					<Typography className={classes.title}>
						{title}
					</Typography>
					<Typography className={classes.description}>
						{description}
					</Typography>
					{ingredients &&
						<Fragment>
							<Typography className={classes.category} style={{ marginTop: '1.5rem' }}>
								{t("food.product.ingredients")}
							</Typography>
							<Typography className={classes.description} style={{ marginTop: '0rem' }}>
								{ingredients}
							</Typography>
						</Fragment>}
				</Box>
			</Grid>

			<Grid item xs={12} sm={3} md={4} style={{ display: 'flex' }} alignItems='center' >
				<DetailCardComponent details={details} />
			</Grid>
		</Grid>
	)
}

export default ProductDetailComponent