import React, { Fragment } from "react";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { useParams } from "react-router";
import FoodProductsSellerContainer from "./FoodProductsSeller.container";
import ProductDetailComponent from "../../components/product/ProductDetail.component";
import FoodSellerContainer from "./FoodSeller.container";
import LoadingComponent from "../../components/common/Loading.component";
import { useTranslation } from 'react-i18next';
import DetailHeaderComponent from "../../components/header/DetailHeader.component";
import FoodImg from '../../assets/img/food.jpg'
import _ from 'lodash'

const FoodProductContainer = () => {

	const { id } = useParams();
	const { loading, data } = useSWRRequest(Requests.agrifood.product, { id });
	const { t, i18n } = useTranslation()

	return (
		<Fragment>

			{loading && <LoadingComponent />}

			<DetailHeaderComponent
				img={FoodImg}
				name={data?.seller.company_name}
				description={''}
				territory={data?.seller.area.name}
				country={data?.seller.country}
				chamber_commerce={data?.seller.chamber_commerce.name}
				city={data?.seller.city}
				web_site={data?.seller.web_site}
				logo={data?.seller.logo}
				seller_id={data?.seller.id} />

			<ProductDetailComponent
				category={data?.category?.name}
				title={data?.name}
				ingredients={data?.ingredients}
				description={data?.[`description_${i18n.language === 'it' ? 'ita' : 'eng'}`]}
				image={data?.image}
				details={[
					{
						category: t("food.product.format"),
						description: data?.format,
					},
					{
						category: t("food.product.producing_capacities"),
						description: data?.category.name,
					},
					{
						category: t("food.product.shelf_lifes"),
						description: data?.shelf_life.name,
					},
					{
						category: t("food.product.packages"),
						description: data?.package.name,
					},
					{
						category: t("food.product.certifications"),
						description: data ? _.split(data.certifications, ',') : '',
					},
				]}
			/>
			{data ? <FoodSellerContainer seller={data?.seller.id} /> : null}
			{data ? <FoodProductsSellerContainer seller={data?.seller.id} /> : null}
		</Fragment>
	);
};

export default FoodProductContainer;
