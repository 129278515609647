import React, { useCallback, useState } from 'react';
import { FormControlLabel, FormControl, FormLabel, Box, Collapse, Grid } from '@material-ui/core';
import classes from './checkboxButtons.module.css'
import StyledCheckboxComponent from './StyledCheckbox.component';
import _ from 'lodash'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const CheckboxButtonComponent = ({
    id,
    label,
    options,
    ...props
}) => {

    const [open, setOpen] = useState(false);

    const onChange = useCallback((item) => {
        if (_.includes(props.values[id], item)) {
            props.setFieldValue(id, props.values[id].filter((obj) => obj !== item))
        } else {
            props.setFieldValue(id, [...props.values[id], item])
        }
    }, [props.values[id]])

    return (

        <FormControl style={{ width: '100%' }}>
            <FormLabel>
                <Box display='flex' alignItems='center' mb={1} onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
                    <Box flexGrow={1} className={classes.label}>{label}</Box>
                    <Box>{open ? <ExpandMoreIcon /> : <ExpandLessIcon />}</Box>
                </Box>
            </FormLabel>
            <Collapse in={open}>
                <Grid container>
                    {options.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <FormControlLabel
                                value={item.id}
                                control={
                                    <StyledCheckboxComponent
                                        id={id}
                                        onChange={() => onChange(item.id)}
                                        checked={_.includes(props.values[id], item.id)} />
                                }
                                label={
                                    <Box className={classes.options}>
                                        {item.label}
                                    </Box>
                                } />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </FormControl >
    );
}

export default CheckboxButtonComponent;