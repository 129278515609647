import React, { Fragment } from "react";
import IconButtonComponent from "../../buttons/IconButton.component";
import { Box, Divider, Grid } from "@material-ui/core";
import { FiLogIn } from "react-icons/fi";
import classes from "./dialog.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Menu } from "../../../config/Menu.config";


const ContentDialogComponent = () => {

	const { token } = useSelector(state => state.user)
	const { t } = useTranslation()

	return (
		<Box className={classes.content}>

			{!token &&
				<Link to="/login" style={{ textDecoration: "none" }}>
					<IconButtonComponent
						title={t("login.button")}
						icon={<FiLogIn />}
						backgroundColor="#796D57"
						width="7.625rem"
						height="2.625rem"
						textTransform="uppercase"
						fontSize="1.1rem" />
				</Link>
			}
	
			<Link to="/food_products" style={{ textDecoration: "none" }}>
				<Box className={classes.text1}>{t("menu.food&drink.title")}</Box>
			</Link>

			<Link to="/tourism_sellers" style={{ textDecoration: "none" }}>
				<Box className={classes.text2}>{t("menu.tourism.title")}</Box>
			</Link>

			<Grid container spacing={1}>
				<Grid item xs={6} sm={8} md={12}>
					<Box width="100%" className={classes.dividergrid}>
						<Divider />
					</Box>
				</Grid>
				<Grid container item xs={12} spacing={3}>
					{Menu.map((item) =>
						<Fragment key={item.title}>
							<Grid container item xs={12} md={4}>
								<Grid item xs={12}>
									<Box className={classes.title}>
										{t(item.title)}
									</Box>
									<Box mt={2}>
										{item.items.map((subitem) => (
											<Link to={subitem.link} style={{ textDecoration: 'none' }}>
												<Box className={classes.textbox}>
													{t(subitem.title)}
												</Box>
											</Link>
										))}
									</Box>
								</Grid>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default ContentDialogComponent;
