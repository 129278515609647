import React from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import FilterComponent from '../../components/filter/Filter.component'
import { Formik, Form } from 'formik';
import CheckboxButtonComponent from '../../components/filter/components/CheckboxButton.component';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const TourismBuyersFilterContainer = ({
    setFilter = () => {}
}) => {

    // eslint-disable-next-line
    const { data: categories } = useSWRRequest(Requests.tourism.categories)
    const { data: services } = useSWRRequest(Requests.tourism.services)
    const { data: sectors } = useSWRRequest(Requests.tourism.sectors)

    const { t } = useTranslation()

    return (
        <Formik
            initialValues={{
                categories: [],
                services: [],
                sectors: []
            }}
            onSubmit={(values) => setFilter(values)}
            onReset={(values) => setFilter(values)}>
            {(props) => (
                <Form>
                    <FilterComponent onReset={props.resetForm} onSubmit={props.submitForm}>                        

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.category')}
                                id='categories'
                                options={categories}
                                {...props} />
                        </Box>

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.service')}
                                id='services'
                                options={services}
                                {...props} />
                        </Box>

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.sector')}
                                id='sectors'
                                options={sectors}
                                {...props} />
                        </Box>

                    </FilterComponent>
                </Form>
            )}
        </Formik>
    )
}

export default TourismBuyersFilterContainer