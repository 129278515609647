import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import _ from 'lodash';

export class ComponentToPrint extends React.PureComponent {

    render() {

        let companyName = this.props.companyName
        let appointmentList = this.props.appointmentList
        let user = this.props.user
        let number = this.props.number

        return (
            <div style={{ margin: '1rem 2rem 1rem 2rem' }}>

                <div style={{ textAlign: 'center' }}><img src = "/static/logo.png" style={{ height: '3rem', width: 'auto' }} /></div>
                <div style={{ textAlign: 'center', marginTop: '1rem', fontSize: '1.5rem', fontFamily: 'LiberGrotesque-Bold' }}>MIRABILIA<span style={{  fontSize: '0.5rem' }}>®</span></div>
                <div style={{ textAlign: 'center', fontSize: '0.8rem', fontFamily: "LiberGrotesque" }}></div>
                <div style={{ textAlign: 'center', fontSize: '1.2rem', padding: '3rem', fontFamily: 'LiberGrotesque-Bold' }}>{companyName}</div>
                <table style={{ width: '100%', marginBottom: '2rem', marginTop: '1rem' }}>
                    <thead >
                        <th style={{ verticalAlign: 'text-top', textAlign: 'left', width: '40%', fontSize: '0.8rem', color: '#B91818', padding: '5px' }}>NOME AZIENDA</th>
                        <th style={{ verticalAlign: 'text-top', textAlign: 'center', width: '20%', fontSize: '0.8rem', color: '#B91818', padding: '5px' }}>DATA PRENOTATA</th>
                        <th style={{ verticalAlign: 'text-top', textAlign: 'center', width: '20%', fontSize: '0.8rem', color: '#B91818', padding: '5px' }}>ORA PRENOTATA</th>
                        <th style={{ verticalAlign: 'text-top', textAlign: 'center', width: '20%', fontSize: '0.8rem', color: '#B91818', padding: '5px' }}>TAVOLO: {number}</th>
                    </thead>
                    <tbody>
                   { appointmentList?.map((appointment, index) =>  
                        <tr key={index}>
                            <td style={{ verticalAlign: 'text-top', padding: '5px' }}>{user.type.id === 4 || user.type.id === 2 ? appointment.buyer.company_name : appointment.seller.company_name}</td>
                            <td style={{ verticalAlign: 'text-top', textAlign: 'center', padding: '5px' }}>{moment(appointment.date).format('DD-MM-YYYY')}</td>   
                            <td style={{ verticalAlign: 'text-top', textAlign: 'center', padding: '5px' }}>{_.slice(appointment.time, 0, 5)}</td>
                            <td style={{ verticalAlign: 'text-top', textAlign: 'center', padding: '5px' }}>{user.type.id === 4 || user.type.id === 2 ? appointment.buyer.number : appointment.seller.number}</td>
                        </tr>
                        ) }
                    </tbody>
                </table>
            </div>
        );
    }
}

export const AppointmentToPDF = ({
    onAfterPrint = () => { },    
    appointmentList,
    user
}) => {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'mirabilia',
        onAfterPrint: onAfterPrint
    })

    useEffect(() => {
        handlePrint()
    }, [])


    const getPageMargins = () => {
        return `@page { margin: 2rem 1rem 2rem 1rem !important; }`;
    };

    return (
        <div style={{ margin: '1rem', display: 'none' }}>            
            <div style={{ border: '1px solid', margin: '1rem 0 1rem 0' }}>
                <style>{getPageMargins()}</style>
                <ComponentToPrint ref={componentRef} companyName={user.company?.company_name} number={user?.company.number} appointmentList={appointmentList} user={user} />
            </div>            
        </div>
    );
};
