import React, { Fragment, useCallback, useState } from 'react'
import MeetingComponent from '../../components/private/meeting/Meeting.component';
import ModalComponent from '../../components/common/Modal.component';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import _ from 'lodash'
import { useSelector } from 'react-redux';
import AppointmentAddContainer from "../profile/AppointmentAdd.container";

const MeetingListContainer = () => {

    const [openAdd, setOpenAdd] = useState(false)
    const { token, user } = useSelector(state => state.user)   
    const { data } = useSWRRequest(user.type.id === 2 || user.type.id === 3 ? Requests.appointments.agrifood_meeting : Requests.appointments.tourism_meeting, { token: token.access })

    const onSetOpenAdd = useCallback(() => {
        setOpenAdd(!openAdd)
    }, [openAdd])


    return (
        <Fragment>
            <MeetingComponent data={data} setOpenAdd={onSetOpenAdd} />
            <ModalComponent
                title='Conferma appuntamento'
                onClose={onSetOpenAdd}
                open={openAdd}
                fullWidth={true}
                maxWidth='md'>
                <AppointmentAddContainer onClose={onSetOpenAdd}/>
            </ModalComponent>
        </Fragment>

    )
}

export default MeetingListContainer;