export const ProductDetails = [
  {
    category: "Formato del prodotto",
    description: "lt 0,50",
  },
  {
    category: "Shelf life",
    description: "lt 0,50",
  },
  {
    category: "Capacità massima di produzione",
    description: "> 200 unità",
  },
  {
    category: "Packaging",
    description: "100% riciclabile",
  },
];

export const AccomodationDetails = [
  {
    category: "Lorem ipsum",
    description: "200 MQ",
  },
  {
    category: "Lorem ipsum",
    description: "0.50K",
  },
  {
    category: "Lorem ipsum",
    description: "> 200k unità",
  },
  {
    category: "Lorem ipsum",
    description: "riciclabile",
  },
];
