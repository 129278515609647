import React from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import FilterComponent from '../../components/filter/Filter.component'
import { Formik, Form } from 'formik';
import CheckboxButtonComponent from '../../components/filter/components/CheckboxButton.component';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FoodSellersFilterContainer = ({
    setFilter = () => {}
}) => {

    // eslint-disable-next-line
    const { data: chambers_commerce } = useSWRRequest(Requests.agrifood.chambers_commerce)    
    const { t } = useTranslation()

    return (
        <Formik
            initialValues={{                                
                chamber_commerce: []
            }}
            onSubmit={(values) => setFilter(values)}
            onReset={(values) => setFilter(values)}>
            {(props) => (
                <Form>
                    <FilterComponent onReset={props.resetForm} onSubmit={props.submitForm}>
                        
                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.chamber_commerce')}
                                id='chamber_commerce'
                                options={chambers_commerce}
                                {...props} />
                        </Box>
                    
                    </FilterComponent>
                </Form>
            )}
        </Formik>
    )
}

export default FoodSellersFilterContainer