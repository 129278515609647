import React from 'react';
import { Switch } from "react-router-dom";
import FoodProductPage from '../pages/FoodProduct.page';
import FoodProductsPage from '../pages/FoodProducts.page';
import HomePage from '../pages/Home.page'
import LoginPage from '../pages/Login.page'
import TourismSellerPage from '../pages/TourismSeller.page';
import TourismSellersPage from '../pages/TourismSellers.page';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import DashboardPage from '../pages/Dashboard.page';
import FoodSellersPage from '../pages/FoodSellers.page';
import FoodSellerPage from '../pages/FoodSeller.page';
import FoodBuyersPage from '../pages/FoodBuyers.page';
import TourismBuyersPage from '../pages/TourismBuyers.page';
import FAQPage from '../pages/FAQ.page';
import AboutPage from '../pages/About.page';
import PartnersPage from '../pages/Partners.page';
import ContactsPage from '../pages/Contacts.page';
import MeetingListContainer from '../container/meeting/MeetingList.container';


const Router = () => {
	return (
		<Switch>

			<PublicRoute exact path="/">
				<HomePage />
			</PublicRoute>

			<PublicRoute exact path="/login">
				<LoginPage />
			</PublicRoute>

			<PublicRoute exact path="/food_products">
				<FoodProductsPage />
			</PublicRoute>

			<PublicRoute exact path="/food_products/:id">
				<FoodProductPage />
			</PublicRoute>

			<PublicRoute exact path="/food_sellers">
				<FoodSellersPage />
			</PublicRoute>

			<PublicRoute exact path="/food_sellers/:id">
				<FoodSellerPage />
			</PublicRoute>

			<PublicRoute exact path="/food_buyers">
				<FoodBuyersPage />
			</PublicRoute>

			<PublicRoute exact path="/tourism_sellers">
				<TourismSellersPage />
			</PublicRoute>

			<PublicRoute exact path="/tourism_seller/:id">
				<TourismSellerPage />
			</PublicRoute>

			<PublicRoute exact path="/tourism_buyers">
				<TourismBuyersPage />
			</PublicRoute>

			<PublicRoute exact path="/faq/:id">
				<FAQPage />
			</PublicRoute>

			<PublicRoute exact path="/about">
				<AboutPage />
			</PublicRoute>

			<PublicRoute exact path="/partners">
				<PartnersPage />
			</PublicRoute>

			<PublicRoute exact path="/contacts">
				<ContactsPage />
			</PublicRoute>

			<PrivateRoute path='/dashboard'>
				<DashboardPage />
			</PrivateRoute>

		</Switch>
	)
}

export default Router;