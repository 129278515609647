import React from 'react'
import { makeStyles, IconButton, Box } from '@material-ui/core'
import { FaTrashAlt } from "react-icons/fa";

const useStyles = makeStyles({
	list: {
		color: "#796D57",
		fontFamily: "LiberGrotesque-Bold",
		alignItems: "center",
		display: "flex",
		margin: "0.5rem 0rem",
		"& .MuiSvgIcon-root": {
			marginRight: "0.5rem",
			alignItems: "center",
		},
		"& svg": {
			fontSize: "1rem",
		},
		width: '100%',
		justifyContent: 'flex-end'
	},
	iconButton: {
		textDecoration: 'none',
		color: '#796D57',
		padding: '0.5rem',
	},
})

const DeleteMeetingComponent = ({
	setOpenAdd
}) => {
	const classes = useStyles()
	return (
		<Box className={classes.list} onClick={setOpenAdd}>
			<IconButton>
				<FaTrashAlt className={classes.iconButton} />
			</IconButton>
		</Box>
	)
}

export default DeleteMeetingComponent