import React, { useState } from "react";
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import TourismSellersContainer from "../container/tourism/TourismSellers.container";
import TourismSellersFilterContainer from "../container/tourism/TourismSellersFilter.container";
import FiltersLayout from "../layout/Filters.layout";
import TourismImg from "../assets/img/tourism.jpg";
import { useTranslation } from 'react-i18next';


const TourismSellersPage = () => {

    const [filter, setFilter] = useState(null)
    const { t } = useTranslation()

    return (
        <FiltersLayout
            header={
                <CatalogueHeaderComponent
                    img={TourismImg}
                    title={t('tourism.products.title')}
                    subtitle={t('tourism.products.subtitle')}
                />}
            filters={<TourismSellersFilterContainer setFilter={setFilter} />}
            list={<TourismSellersContainer filter={filter} />} />
    );
};

export default TourismSellersPage;