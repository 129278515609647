import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent} from "@material-ui/core";
import { Link } from "react-router-dom";
import _ from 'lodash'

const SellerCardComponent = ({
    name = "",
    chamber_commerce = '',
    image,
    sellerId
}) => {

    const classes = useStyles();

    return (
        <Link to={`/food_sellers/${sellerId}`} style={{ textDecoration: 'none' }}>
            <Card className={classes.root}>
                <img className={classes.media} src={_.replace(image, 'open', 'uc' )} />
                <CardContent className={classes.cardContent}>
                    <div className={classes.content}>
                        <Typography className={classes.title} variant="h5">
                            {name}
                        </Typography>
                        <Typography className={classes.subtitle} variant="body1">
                            {chamber_commerce}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </Link>
    );
};

const useStyles = makeStyles({
    root: {
        textAlign: "center",
        borderRadius: "1rem",
        alignItems: "center",
        overflow: "visible",
        margin: "0.5rem",
        transition: "300ms",
        minHeight: "300px",
        //boxShadow: '0px 0px 0px',
        cursor: "pointer",
        backgroundColor: 'transparent',
        overflow: 'hidden',
        "&:hover": {
            top: "1rem",
            backgroundColor: '#fff',
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            "& $cardButton": {
                opacity: "1",
            },
            "& $feedback": {
                opacity: "1",
            },
        },
    },
    media: { 
        minHeight: "200px",       
        height: "200px",
        maxHeight: "200px",       
        width: '100%',
        objectFit: 'contain'       
    },
    content: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        color: "#796D57",
        fontWeight: "700",
        fontSize: "1rem",
        fontFamily: 'LiberGrotesque-Bold',
    },
    subtitle: {
        color: "red",
        fontWeight: "500",
        fontSize: "1rem",
        fontFamily: 'LiberGrotesque-Bold',
    },
    cardContent: {
        padding: "1rem",
        "&:last-child": {
            padding: "0.5rem",
            paddingBottom: "1.5rem",
        },
    },
});

export default SellerCardComponent;
