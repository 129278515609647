import React, { Fragment } from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../../components/common/Loading.component';

const useStyles = makeStyles({
    container: {
        padding: '2rem',
    },
    title: {
        fontFamily: 'LiberGrotesque-Bold',
        fontSize: '24px',
        color: '#B91818'
    },
    description: {
        fontFamily: 'LiberGrotesque-Bold',
        fontSize: '14px',
        marginTop: '1.5rem',
        color: '#796d57',
    },
})


const FoodSellerContainer = ({
    seller
}) => {

    const classes = useStyles()
    const { loading, data } = useSWRRequest(Requests.agrifood.seller, { seller })
    const { t, i18n } = useTranslation()

    return (
        <Fragment>            
            {loading && <LoadingComponent />}
            <Box className={classes.container}>
                <Typography className={classes.title}>{t('food.seller.description')}</Typography>
                <Typography className={classes.description}>{data?.[`description_${i18n.language === 'it' ? 'ita' : 'eng'}`]}</Typography>
            </Box>
        </Fragment>

    )
}

export default FoodSellerContainer