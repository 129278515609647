import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import InsertLinkRoundedIcon from "@material-ui/icons/InsertLinkRounded";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import Modal from '../common/Modal.component'

const BuyerTourismCardComponent = ({
    item,	
}) => {
    
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <Card className={classes.root} onClick={() => setOpen(true)}>
                <CardContent>
                    <Typography className={classes.nameLabel} variant="h5" component="h2">
                        {item.company_name}
                    </Typography>
                    <Detail item={item} />
                </CardContent>
            </Card>
            {open &&
                <Modal
                    title={item.company_name}
                    onClose={() => setOpen(false)}
                    open={open}
                    maxWidth={'md'}>
                    <Popup item={item} />
                </Modal>
            }
        </Fragment>
    );
};

const Popup = ({
    item
}) => {

    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Typography className={classes.listText}>
                    {`${t('tourism.buyer.city')}: ${item.city ? item.city : ''}`}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography className={classes.listText}>
                    {`${t('tourism.buyer.address')}: ${item.address ? item.address : ''}`}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography className={classes.listText}>
                    {`${t('tourism.buyer.country')}: ${item.country ? item.country : ''}`}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography className={classes.listText}>
                    <InsertLinkRoundedIcon className={classes.icon} />
                    {item.web_site ? item.web_site : ''}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Typography className={classes.listText}>
                    <MailRoundedIcon className={classes.icon} />
                    {item.email ? item.email : ''}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.nameLabel}>
                    {t('tourism.buyer.category')}
                </Typography>
                <Typography className={classes.listText}>
                    {`${item.category ? item.category.name : ''}`}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.nameLabel}>
                    {t('tourism.buyer.sectors')}
                </Typography>
                <Typography className={classes.listText}>
                    {`${item.sectors ? item.sectors.map((item) => `${item.name} ,`) : ''}`}
                </Typography>
            </Grid>


            <Grid item xs={12}>
                <Typography className={classes.nameLabel}>
                    {t('tourism.buyer.services')}
                </Typography>
                <Typography className={classes.listText}>
                    {`${item.services ? item.services.map((item) => `${item.name} `) : ''}`}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.nameLabel}>
                    {t('tourism.buyer.type')}
                </Typography>
                <Typography className={classes.listText}>
                    {`${item.travel_type ? item.travel_type.name : ''}`}
                </Typography>
            </Grid>

        </Grid>
    )
}


const Detail = ({
    item
}) => {

    const classes = useStyles();
    const { t } = useTranslation()

    return (
        <Fragment>
            <ul className={classes.list}>
                <li>
                    <Typography className={classes.listText}>
                        {`${t('tourism.buyer.city')}: ${item.city ? item.city : ''}`}
                    </Typography>
                </li>
            </ul>
            <ul className={classes.list}>
                <li>
                    <Typography className={classes.listText}>
                        {`${t('tourism.buyer.address')}: ${item.address ? item.address : ''}`}
                    </Typography>
                </li>
            </ul>
            <ul className={classes.list}>
                <li>
                    <Typography className={classes.listText}>
                        <InsertLinkRoundedIcon className={classes.icon} />
                        {item.web_site ? item.web_site : ''}
                    </Typography>
                </li>
            </ul>
            <ul className={classes.list}>
                <li>
                    <Typography className={classes.listText}>
                        <MailRoundedIcon className={classes.icon} />
                        {item.email ? item.email : ''}
                    </Typography>
                </li>
            </ul>
        </Fragment>
    )
}

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderRadius: "1rem",
        transition: "300ms",
        overflow: "visible",
        boxShadow: "0px 0px 0px 0px",
        borderStyle: "none",
        "& $cardButton": {
            textAlign: "center",
        },
        "&:hover": {
            top: "1rem",
            boxShadow: "1px 1px 7px 0px rgb(0 0 0 / 30%)",
            "& $cardButton": {
                opacity: "1",
            },
        },
    },
    imageWrapper: {
        margin: "1rem",
        marginLeft: "0rem",
        "& img": {
            display: "flex",
            width: "9rem",
        },
    },
    nameLabel: {
        color: "#B91818",
        fontSize: "1.25rem",
        fontFamily: 'LiberGrotesque-Bold',
    },
    list: {
        listStyle: "none",
        display: "flex",
        flexDirection: "row",
        paddingLeft: "0px",
        "& li": { marginRight: "1.375rem" },
    },
    listText: {
        color: "#796D57",
        fontFamily: 'LiberGrotesque-Bold',
        fontSize: "0.75rem",
        textTransform: "uppercase",
        alignItems: "center",
        display: "flex",
    },
    icon: { marginRight: "0.625rem" },
});

export default BuyerTourismCardComponent;