const LogoComponent = ({
    src = "/static/logo.png",
    height = '30px',
    width = 'auto',
    ...props
}) => (
    <img
        height={height}
        width={width}
        alt="Logo"
        src={src}
        {...props}
    />
);

export default LogoComponent;