import React from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#B91818',
	},
}))(LinearProgress);


const LoadingComponent = () => {

	return <BorderLinearProgress variant="indeterminate" />
}

export default LoadingComponent