import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


const slice = 9

const useStyles = makeStyles({
    empty: {
        textAlign: 'center',
        fontFamily: 'LiberGrotesque-Bold',
        color: '#B91818'
    }
});


const PaginationLayout = ({
    children,
    data,
}) => {

    const classes = useStyles();
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    
    const onChange = useCallback((event, page) => {
        setPage(page - 1)
    }, [])

    useEffect(() => {
        setPage(0)
    }, [data])



    return (
        <Grid container spacing={3} justifyContent='flex-start'>
            {children({ page, slice })}

            {data.length === 0 &&
                <Grid item xs={12}>
                    <Typography className={classes.empty}>{t('filter.empty')}</Typography>
                </Grid>}

            {data.length > slice &&
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center'>
                        <Pagination count={Math.ceil(data.length / slice)} onChange={onChange} />
                    </Box>
                </Grid>
            }


        </Grid>
    );
};

export default PaginationLayout;
