import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import HeaderContainer from "../container/header/Header.container";
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import PartnerComponent from '../components/footer/Partner.component';
import NetworkImg from "../assets/img/network.jpg";
import { useTranslation } from 'react-i18next';

const PartnersPage = () => {

    const { t } = useTranslation()

    return (

        <Fragment>
            <HeaderContainer />
            <CatalogueHeaderComponent img={NetworkImg} title={t('partners.title')} />
            <Container maxWidth={false}>
                <PartnerComponent />
            </Container>
        </Fragment>
    );
}

export default PartnersPage
