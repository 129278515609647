import React, { useCallback, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup';
import AppointmentFormComponent from '../../components/form/AppointmentForm.component';
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'

export const ValidationSchema = {
    schema: Yup.object().shape({
        buyer: Yup.number().required('Campo obbligatorio'),
        seller: Yup.number().required('Campo obbligatorio'),
        date: Yup.string().required('Campo obbligatorio'),
        time: Yup.string().required('Campo obbligatorio'),
    }),
}

const AppointmentAddContainer = ({
    onClose = () => { }
}) => {

    const { token, user } = useSelector(state => state.user)
    const { data: buyers, loading: loadingBuyers } = useSWRRequest(user.type.id === 2 || user.type.id === 3 ? Requests.agrifood.buyers : Requests.tourism.buyers, { token: token.access })
    const { data: sellers, loading: loadingSellers } = useSWRRequest(user.type.id === 2 || user.type.id === 3 ? Requests.agrifood.sellers : Requests.tourism.sellers, { token: token.access })
    const { data: appointments, loading: loadingAppointments, mutate } = useSWRRequest(user.type.id === 2 || user.type.id === 3 ? Requests.appointments.agrifood_meeting : Requests.appointments.tourism_meeting, { token: token.access })
    const loading = useMemo(() => loadingAppointments && loadingBuyers && loadingSellers, [loadingAppointments, loadingBuyers, loadingSellers])


    const onSuccess = useCallback(() => {
        mutate()
        onClose()
    }, [])

    const onFailure = useCallback(() => {

    }, [])

    const onSubmit = useCallback((values) => {

        const data = {
            ...values,
            token: token.access,
            time: `${values.time}:00`,
            date: `2021-11-${values.date}`
        }

        if (user.type.id === 2 || user.type.id === 3) {
            Requests.appointments.add_agrifood_meeting(data).then(() => onSuccess()).catch((error) => onFailure(error))
        } else if (user.type.id === 4 || user.type.id === 5) {
            Requests.appointments.add_tourism_meeting(data).then(() => onSuccess()).catch((error) => onFailure(error))
        }

    }, [])

    return (
        <Formik
            validationSchema={ValidationSchema.schema}
            onSubmit={onSubmit}
            initialValues={{
                buyer: null,
                seller: null,
                date: null,
                time: null
            }}>
            {(props) => (
                <Form>
                    {!loading &&
                        <AppointmentFormComponent
                            {...props}
                            buyers={user.type.id === 3 || user.type.id === 5 ? buyers.filter((buyer) => buyer.id === user.company.id) : buyers}
                            sellers={user.type.id === 2 || user.type.id === 4 ? sellers.filter((seller) => seller.id === user.company.id) : sellers}
                            appointments={appointments} />}
                </Form>
            )}
        </Formik>
    )
}

export default AppointmentAddContainer;