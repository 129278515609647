import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Container, Box } from "@material-ui/core";
import HeaderDialogComponent from "./HeaderDialog.component";
import classes from "./dialog.module.css";
import ContentDialogComponent from "./ContentDialog.component";

const noop = () => { };
const DialogComponent = ({ open, handleClose = noop, languages, onChangeLanguage }) => {
	return (
		<Dialog
			fullScreen
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			PaperProps={{
				style: {
					backgroundColor: "RGBA(0, 0, 0, 0.9)",
					boxShadow: "none",
				},
			}}
		>
			<DialogContent style={{ padding: "0px" }}>
				<Container maxWidth={false}>
					<HeaderDialogComponent languages={languages} onChangeLanguage={onChangeLanguage} handleClose={handleClose} />
					<Box className={classes.container}>
						<ContentDialogComponent languages={languages} />
					</Box>
				</Container>
			</DialogContent>
		</Dialog>
	);
};

export default DialogComponent;
