import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Typography,
	Card,
	CardContent,
	CardMedia,
	Box,
} from "@material-ui/core";

import { useTranslation } from 'react-i18next';

const TerritoryCard = ({
	title = "",
	subtitle = "",
	image
}) => {

	const { t } = useTranslation()
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardMedia image={image} src={image}>
				<CardContent className={classes.content}>
					<Box display='flex' flexDirection='column' justifyContent='flex-end' className={classes.box} >
						<Typography className={classes.title} variant="h5">{t(`${title}`)}</Typography>
						<Typography className={classes.subtitle} variant="body1">{t(`${subtitle}`)}</Typography>
					</Box>
				</CardContent>
			</CardMedia>
		</Card>
	);
};

const useStyles = makeStyles({
	root: {
		borderRadius: "2rem",
		minWidth: '280px',
		marginRight: '1.5rem'
	},
	content: {
		maxWidth: '320px',
		height: '420px',
		position: 'relative',
		background: '#00000030',
	},
	box: {
		height: '100%'
	},
	title: {
		textAlign: 'center',
		textShadow: '0px 0px 10px #000000',
		fontFamily: "LiberGrotesque-Black",
		color: '#fff',
		wordWrap: 'break-word',
	},
	subtitle: {
		textAlign: 'center',
		textShadow: '0px 0px 10px #000000',
		color: '#fff',
		fontFamily: "LiberGrotesque-SemiBold",
	},
});

export default TerritoryCard;
