import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Grid, List, Divider, TextField } from '@material-ui/core';
import ChatComponent from '../../components/chat/Chat.component';
import { Formik, Form, Field } from 'formik';
import { MessageChatValidationSchema, MessageChatInitialValues } from '../../config/MessageChat.config';
import Requests from '../../core/requests';
import { useSelector } from 'react-redux';
import useSWRRequest from '../../core/useSWRRequest';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles({
    messageArea: {
        height: '55vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse'
    },
    messageSendArea: {
        padding: '1.2rem'
    }
})

const ChatMessageContainer = ({
    selectedChatId
}) => {

    const [chatData, setChatData] = useState()
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const { token, user } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(Requests.conversations.retrieveChats, { id: selectedChatId, token: token.access });

    useEffect(() => {
        setChatData(_.values(data))
    }, [data])

    const classes = useStyles();

    const onSuccess = useCallback(() => {
        setLoadingSubmit(false)
    }, [])

    const onFailure = useCallback((error) => {
        // setError(error)
        setLoadingSubmit(false)
    }, [])


    const onSubmit = useCallback((values, { resetForm }) => {
        setLoadingSubmit(true)
        Requests.conversations.newMessage({ text: values.text, user_to: user.id === data.user_from.id ? data.user_to.id : data.user_from.id, token: token.access }).then(() => onSuccess()).catch((error) => onFailure(error))
        resetForm({ values: '' })
    }, [data])

    return (
        data &&
        <Grid item xs={8}>
            <List className={classes.messageArea}>
                {chatData && chatData[1]?.map((message, index) => <ChatComponent key={index} message={message} user={user} />)}
            </List>
            <Divider />
            <Formik
                validationSchema={MessageChatValidationSchema.schema}
                onSubmit={onSubmit}
                initialValues={MessageChatInitialValues}>
                {(props) => (
                    <Form>
                        <Grid container className={classes.messageSendArea} spacing={2} alignItems='center'>
                            <Grid item xs={10}>
                                <TextField
                                    name='text'
                                    onChange={props.handleChange}
                                    value={props.values.text}
                                    multiline
                                    minRows={2}
                                    maxRows={3}
                                    variant='outlined'
                                    fullWidth
                                    // color="warnig"
                                    autoFocus={true} />
                            </Grid>
                            <Grid xs={1} align="right">
                                <Fab type="submit" color="inherit" aria-label="add"><SendIcon /></Fab>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
}

export default ChatMessageContainer;