import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    top_image: props => ({
        backgroundImage: `url(${props.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'top',        
        backgroundSize: 'cover',

    }),
    content: {
        position: 'relative',
        background: '#00000050',
        minHeight: '30rem'
    }
}));

export default function TopImageLayout({
    image,
    children,
    minHeight = '30rem'
}) {

    const classes = useStyles({ image, minHeight });

    return (
        <Box className={classes.top_image}>            
            <Box className={classes.content}>
                {children}
            </Box>    
        </Box>

    );
}