import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import IconButtonComponent from "../buttons/IconButton.component";
import classes from "./footer.module.css";
import { FiLogIn } from 'react-icons/fi';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import TopImageLayout from "../../layout/TopImg.layout";
import NetworkImg from "../../assets/img/network.jpg";
import SellerImg from "../../assets/img/seller.jpeg";
import BuyerImg from "../../assets/img/buyer.jpg";

const NetworkComponent = () => {

	const { token } = useSelector(state => state.user)
	const { t } = useTranslation()

	return (
		<TopImageLayout image={NetworkImg}>
			<Grid container className={classes.networkWrapper}>
				<Grid item xs={12} md={6} sm={12}>
					<Box p={4}>
						<Typography
							variant="h4"
							gutterBottom
							className={classes.networkTitle}>
							{t('footer.network.title')}
						</Typography>
						<Typography
							variant="subtitle1"
							gutterBottom
							className={classes.networkSubtitle}>
							{t('footer.network.subtitle')}
						</Typography>
						{!token &&
							<Link to='/login' style={{ textDecoration: 'none' }}>
								<IconButtonComponent
									title={t('login.button')}
									icon={<FiLogIn />}
									backgroundColor="#796D57"
									width='7.625rem'
									height='2.625rem'
									textTransform='uppercase'
									fontSize='1.1rem'
								/>
							</Link>}
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Box className={classes.networkCard}>
						<img
							src={SellerImg}
							className={classes.imageNetwork}
							alt={''} />
						<Typography className={classes.networkMidtitle}>
							{t('footer.network.seller_title')}
						</Typography>
						<Typography variant="subtitle1" gutterBottom className={classes.networkSubtitle}>
							{t('footer.network.seller_subtitle')}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<Box className={classes.networkCard}>
						<img
							src={BuyerImg}
							className={classes.imageNetwork}
							alt={''}
						/>
						<Typography className={classes.networkMidtitle}>
							{t('footer.network.buyer_title')}
						</Typography>
						<Typography variant="subtitle1" gutterBottom className={classes.networkSubtitle}>
							{t('footer.network.buyer_subtitle')}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</TopImageLayout>
	);
};

export default NetworkComponent;
