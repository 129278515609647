import React from 'react'
import TerritoryCarouselComponent from '../../components/carousel/TerritoryCarousel.component';
import { Territories } from '../../config/Territory.config';


const TerritoryContainer = () => {

    return <TerritoryCarouselComponent territories={Territories}/>        
}

export default TerritoryContainer