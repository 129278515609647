import React from "react";
import {
  TextField,
  InputAdornment,
  makeStyles,
  Box,
  Grid,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import IconButtonComponent from "../buttons/IconButton.component";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "white",
    fontSize: "1.4rem",
  },
  root: {
    height: "3.125rem",
    color: "white",
    "& .MuiInputBase-input": {
      color: "white",
      fontSize: "1.2rem",
    },

    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid white",
    },
  },
}));

const SearchHomeComponent = ({ label, handleChange }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box pt={1}>
          <TextField
            className={classes.root}
            placeholder={label}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.box}>
                  <SearchIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <IconButtonComponent
          title="Cerca"
          textTransform="uppercase"
          backgroundColor="#B91818"
          width="11.25rem"
          height="3.063rem"
        />
      </Grid>
    </Grid>
  );
};

export default SearchHomeComponent;
