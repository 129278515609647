import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#796D57",
    color: "#fff",
  },
  paper: {
    borderRadius: "1rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "inherit",
  },
  header: {
    fontSize: "1.75rem",
    fontFamily: 'LiberGrotesque-Bold',
  },
  '& .MuDialogContent-root': {
    overflow: 'hidden',
  }
}));

const Modal = ({
  title,
  onClose,
  open,
  children,
  fullWidth = false,
  maxWidth = "sm",
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={classes.root} disableTypography>
        <Box display="flex">
          <Box flexGrow={1} pt={1}>
            <Typography className={classes.header}>{title}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose} className={classes.closeButton}>
              <CancelRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers >{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
