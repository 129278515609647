import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card, CardContent, CardMedia, Button, Box, Grid } from "@material-ui/core";
import { useTranslation } from 'react-i18next';


const CategoryCard = ({
	title = '',
	subtitle = "",
	buttonTitle = '',
	image
}) => {

	const { t } = useTranslation()

	const classes = useStyles();

	return (
		<Fragment>
			<Card className={classes.root}>
				<CardMedia image={image} src={image}>
					<CardContent className={classes.content}>
						<Box display='flex' flexDirection='column' justifyContent='flex-end' className={classes.box} >
							<Typography className={classes.title} variant="h3">{t(`${title}`)}</Typography>
							<Typography className={classes.subtitle} variant="body1">{t(`${subtitle}`)}</Typography>
						</Box>
					</CardContent>
				</CardMedia>
			</Card>
			<Box display='flex' justifyContent='center'>
				<Button className={classes.cardButton}>{t(`${buttonTitle}`)}</Button>
			</Box>

		</Fragment>
	);
};

const useStyles = makeStyles({
	root: {
		borderRadius: "2rem",
	},
	content: {
		maxWidth: '320px',
		minHeight: '320px',
		position: 'relative',
		background: '#00000040',
	},
	box: {
		minWidth: '320px',
		minHeight: '320px',
		paddingBottom: '1rem'
	},
	title: {
		textAlign: 'center',
		textShadow: '0px 0px 10px #000000',
		fontFamily: "LiberGrotesque-Black",
		color: '#fff',
		wordWrap: 'break-word',
		["@media (max-width:426px)"]: {
			fontSize: "2rem",
		},
	},
	subtitle: {
		textAlign: 'center',
		textShadow: '0px 0px 10px #000000',
		color: '#fff',
		fontFamily: "LiberGrotesque-SemiBold",
	},
	cardButton: {
		backgroundColor: "#796D57",
		color: "#fff",
		borderRadius: "5rem",
		padding: "0.75rem 1rem",		
		textTransform: 'none',
		top: '-1.5rem',
		fontFamily: 'LiberGrotesque-Bold',
		'&:hover': {
			backgroundColor: "#796D57",
			color: "#fff",
		}
	},
});

export default CategoryCard;
