import React, { Fragment } from "react";
import HeaderContainer from "../container/header/Header.container";
import TourismSellerContainer from "../container/tourism/TourismSeller.container";

const TourismSellerPage = () => {
    return (
        <Fragment>
            <HeaderContainer />
            <TourismSellerContainer />
        </Fragment>
    );
};

export default TourismSellerPage;