import React from 'react';
import { Grid, Box } from '@material-ui/core'
import { FaFilter } from 'react-icons/fa';
import classes from './headerFilterCard.module.css'
import { useTranslation } from 'react-i18next';

const HeaderFilterCardComponent = () => {

    const { t } = useTranslation()

    return (
        <Grid container spacing={1} className={classes.headerCard}>
            <Grid item>
                <FaFilter className={classes.iconFilter} />
            </Grid>
            <Grid item xs>
                <Box className={classes.textHeader}>{t('filter.title')}</Box>
            </Grid>            
        </Grid>
    )
}

export default HeaderFilterCardComponent;

