import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Container } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/img/logo_mirabilia.svg';
import { companyInfo } from '../../config/Footer.config';
import classes from './footer.module.css'
import { Menu } from '../../config/Menu.config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FooterComponent = () => {

	const [mobileView, setMobileView] = useState(false);
	useEffect(() => {
		const setResponsiveness = () => setMobileView(window.innerWidth < 768)
		setResponsiveness();
		window.addEventListener("resize", () => setResponsiveness());
		return () => {
			window.removeEventListener("resize", () => setResponsiveness());
		};
	}, []);

	const { t } = useTranslation()

	return (
		<Box className={classes.wrapper}>
			<Container maxWidth={false}>

				<Box p={4}>

					<Grid container display='flex' justifyContent='center'>
						<Grid item xs={12} className={classes.item}>
							<Logo style={{ marginBottom: '1rem' }} />
						</Grid>

						{Menu.map((item) =>
							<Grid item xs={12} sm={3}>
								<Box display='flex' flexDirection='column' p={1} className={classes.menuContainer}>
									<Typography className={classes.title}>{t(item.title)}</Typography>
									{item.items.map((item, index) =>
										<Typography className={classes.text} key={index}>
											<Link className={classes.link} to={item.link}>
												{t(item.title)}
											</Link>
										</Typography>
									)}

								</Box>
							</Grid>
						)}

						<Grid item xs={12} sm={3}>
							<Box display='flex' flexDirection='column' p={1} className={classes.menuContainer}>
								<Typography className={classes.title}>Mirabilia Network Association</Typography>
								<Typography className={classes.text}>{"Registered office:"}</Typography>
								<Typography className={classes.text}>{`${companyInfo.registered_street} - ${companyInfo.registered_city}`}</Typography>
								<Typography className={classes.text}>{"Operational headquarter:"}</Typography>
								<Typography className={classes.text}>{`${companyInfo.headquarter_street} - ${companyInfo.headquarter_city}`}</Typography>
								<Typography className={classes.text}>{companyInfo.email}</Typography>
								<Typography className={classes.text}>{`VAT ID ${companyInfo.vat}`}</Typography>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export default FooterComponent;
