import { Grid, Typography, Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: "1rem",
		minHeight: 300,
		backgroundColor: '#E5DED4',
		color: "#fff",
		borderRadius: "1rem",
		marginTop: '2rem',
	},
	title: {
		fontFamily: "LiberGrotesque-Bold",
		color: "#796D57",
		fontSize: "2rem",
	},
	subtitle: {
		fontFamily: "LiberGrotesque-SemiBold",
		color: "#796D57",
	},
	text: {
		fontFamily: "LiberGrotesque-Regular",
		color: "#796D57",
		fontSize: "1rem",
		marginBottom: "0.5rem",
	},
	profileBox: {
		marginTop: "1rem",
		maxWidth: "150px",
		"& img": {
			maxWidth: "150px",
		},
	},
	paper: {
		backgroundColor: 'white',
		borderRadius: "1rem",
		padding: theme.spacing(1)
	},
	data: {
		color: "#b91818",
		padding: "0.7rem 0rem"
	}

}));

const ProfileComponent = ({
	first_name,
	last_name,
	email,
	web_site,
	logo,
	description
}) => {

	const classes = useStyles();

	return (

		<Grid container style={{ padding: '3rem' }}>
			<Typography className={classes.title}>Il mio profilo</Typography>
			<Grid item xs={12}>
				<Card className={classes.root}>
					<CardContent>

						<Grid item xs={12}>
							<Box className={classes.profileBox}>
								<Typography className={classes.text}>Immagine profilo</Typography>
								<img alt='' src={_.replace(logo, 'open', 'uc' )}  />
							</Box>
						</Grid>

						<Grid item xs={12} className={classes.data}>
							<Typography className={classes.text}>Nome</Typography>
							<Typography className={classes.paper}>{first_name} {last_name}</Typography>
						</Grid>
						<Grid item xs={12} className={classes.data}>
							<Typography className={classes.text}>Email</Typography>
							<Typography className={classes.paper}>{email}</Typography>
						</Grid>

						<Grid item xs={12} className={classes.data}>
							<Typography className={classes.text}>Web Site</Typography>
							<Typography className={classes.paper}>{web_site}</Typography>
						</Grid>

						<Grid item xs={12} className={classes.data}>
							<Typography className={classes.text}>Descrizione</Typography>
							<Typography className={classes.paper}>{description}</Typography>
						</Grid>

					</CardContent>

				</Card >
			</Grid>
		</Grid >

	);
};

export default ProfileComponent;
