import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
	Grid,
	IconButton,
	FilledInput,
	InputLabel,
	InputAdornment,
	FormControl,
	Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: "100%",
	},
	retrieve: {
		display: "flex",
		flexDirection: "row",
		margin: "1rem",
		alignItems: "center",
	},
	check: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginRight: "0rem",
	},
	text: {
		fontFamily: "LiberGrotesque-Bold",
	},
	register: {
		fontFamily: "LiberGrotesque-Bold",
		color: "#B91818",
		marginLeft: "0.5rem",
		textDecoration: "none",
		alignItems: "center",
	},
	login: {
		fontFamily: "LiberGrotesque-SemiBold",
		color: "#fff",
		backgroundColor: "#B91818",
		width: "100%",		
		"&:hover": {
			color: "#fff",
			backgroundColor: "#B91818",
		},
	},
}));


const LoginFormComponent = ({
	...props
}) => {

	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false)

	const onShowPasswordClick = useCallback(() => (
		setShowPassword(!showPassword)
	), [showPassword])

	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid item xs={12}>
				<FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
					<InputLabel htmlFor="filled-adornment-password">Username</InputLabel>
					<FilledInput
						id="username"
						value={props.values.username}
						name='username'
						onChange={props.handleChange}/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
					<InputLabel htmlFor="filled-adornment-password">
						Password
					</InputLabel>
					<FilledInput
						id="password"
						type={showPassword ? "text" : "password"}
						name='password'
						value={props.values.password}
						onChange={props.handleChange}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={onShowPasswordClick}
									edge="end">
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12}>
				<Button type='submit' className={clsx(classes.margin, classes.login)} >
					Login
				</Button>	
			</Grid>
		</Grid>
	);
};

export default LoginFormComponent;
