import React, { Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TourismImg from "../assets/img/tourism.jpg";
import FoodImg from "../assets/img/food.jpg";
import HeaderContainer from "../container/header/Header.container";
import { useTranslation } from 'react-i18next';
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    heading: {
        fontFamily: "LiberGrotesque-Bold",
    },
    text: {
        fontFamily: "LiberGrotesque-SemiBold",
    }
}));

const FAQPage = () => {

    const classes = useStyles();
    const { i18n, t } = useTranslation()
    const { id } = useParams();
    const img = useMemo(() => id === 'food' ? FoodImg : TourismImg, [id])
    const faq = useMemo(() => {
        let array = []
        const count = id === 'food' ? 6 : 5
        for (let index = 0; index <= count; index++) {
            array.push({
                id: `${id}_${index}`,
                title: t(`${id}.faq.faq_${index}.title`),
                text: t(`${id}.faq.faq_${index}.text`)
            })
        }
        return array

    }, [id, i18n.language])

    return (

        <Fragment>
            <HeaderContainer />
            <CatalogueHeaderComponent img={img} title={t(`${id}.faq.title`)} />
            <Container maxWidth={false}>
                <div className={classes.root}>
                    {faq.map((item) =>
                        <Accordion key={item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${item.id}a-content}`}
                                id={`panel${item.id}a-header}`}>
                                <Typography className={classes.heading} variant='subtitle1'>
                                    {t(item.title)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={classes.text} variant='subtitle2'>
                                    {t(item.text)}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </Container>
        </Fragment>
    );
}

export default FAQPage
