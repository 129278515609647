import React, { Fragment } from "react";
import { Box, Grid } from "@material-ui/core";
import HeaderContainer from "../container/header/Header.container";
import SearchComponent from "../components/search/Search.component";
const FiltersLayout = ({
    header,
    filters,
    list
}) => {

    return (
        <Fragment>
            <HeaderContainer />
            {header}
            <Grid container>
                <Grid item xs={12} sm={5} md={3}>
                    {/* <Box p={2}>
                        <SearchComponent />
                    </Box> */}
                    <Box p={2}>
                        {filters}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={7} md={9}>
                    <Box p={2}>
                        {list}
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default FiltersLayout;