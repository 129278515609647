import React from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import FilterComponent from '../../components/filter/Filter.component'
import { Formik, Form } from 'formik';
import CheckboxButtonComponent from '../../components/filter/components/CheckboxButton.component';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const FoodProductsFilterContainer = ({
    setFilter = () => {}
}) => {

    const { t } = useTranslation()

    // eslint-disable-next-line
    const { data: areas } = useSWRRequest(Requests.agrifood.areas)
    const { data: categories } = useSWRRequest(Requests.agrifood.categories)
    const { data: shelf_lifes } = useSWRRequest(Requests.agrifood.shelf_lifes)
    const { data: producing_capacities} = useSWRRequest(Requests.agrifood.producing_capacities)
    const { data: packages} = useSWRRequest(Requests.agrifood.packages)
    const { data: chambers_commerce} = useSWRRequest(Requests.agrifood.chambers_commerce)


    return (
        <Formik
            initialValues={{
                categories: [],
                shelf_lifes: [],
                producing_capacities: [],
                packages: [],
                area: [],
                chamber_commerce: []
            }}
            onSubmit={(values) => setFilter(values)}
            onReset={(values) => setFilter(values)}>
            {(props) => (
                <Form>
                    <FilterComponent onReset={props.resetForm} onSubmit={props.submitForm}>
                        {/* <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.area')}
                                id='area'
                                options={areas}
                                {...props} />
                        </Box> */}

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.chamber_commerce')}
                                id='chamber_commerce'
                                options={chambers_commerce}
                                {...props} />
                        </Box>

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.categories')}
                                id='categories'
                                options={categories}
                                {...props} />
                        </Box>

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.producing_capacities')}
                                id='producing_capacities'
                                options={producing_capacities}
                                {...props} />
                        </Box>
                        
                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.shelf_lifes')}
                                id='shelf_lifes'
                                options={shelf_lifes}
                                {...props} />
                        </Box>

                        <Box mt={2}>
                            <CheckboxButtonComponent
                                label={t('filter.packages')}
                                id='packages'
                                options={packages}
                                {...props} />
                        </Box>
                    
                    </FilterComponent>
                </Form>
            )}
        </Formik>
    )
}

export default FoodProductsFilterContainer