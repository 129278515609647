import React, { Fragment } from "react";
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import { useParams } from 'react-router'
import ProductDetailComponent from '../../components/product/ProductDetail.component'
import { useTranslation } from 'react-i18next';
import DetailHeaderComponent from "../../components/header/DetailHeader.component";
import TourismImg from '../../assets/img/tourism.jpg'

const TourismSellerPage = () => {
    
    const { id } = useParams()
    const { data } = useSWRRequest(Requests.tourism.seller, { id })

    const { t, i18n } = useTranslation()

    return (
        <Fragment>
            <DetailHeaderComponent 
                img={TourismImg}
                name={data?.company_name}
                description={''}
                territory={data?.area.name}
                country={data?.country}
                chamber_commerce={data?.chamber_commerce.name}
                city={data?.city}
                web_site={data?.web_site}
                logo={data?.logo}/>
            <ProductDetailComponent
                category={data?.denomination}
                title={data?.company_name}
                description={data?.[`description_${i18n.language === 'it'? 'ita' : 'eng'}`]}
                image={data?.logo}
                details={[{
                    category: "Settore",
                    description: data?.sectors?.map((sector) => `${sector.name}`),
                },
                {
                    category: "Categoria",
                    description: data?.category.name,
                },
                {
                    category: "Programmi",
                    description: data?.programmes?.map((program) => `${program.name}\n`),
                },
                {
                    category: "Servizi",
                    description: data?.services?.map((service) => `${service.name}\n`),
                },
                ]} />
        </Fragment>
    );
};

export default TourismSellerPage;