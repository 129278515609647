import _ from 'lodash';

export const AuthEndpoints = {
    basePath: '/api/auth',
    endpoints: {
        login: {
            path: '/token/',
            method: 'POST',
            get: null,
            fallback: null,
        },
        refreshToken: {
            path: '/token/',
            method: 'POST',
            get: 'data',
            fallback: null,
        },
        logout: {
            path: '/token/revoke',
            method: 'POST',
            get: null,
            fallback: null,            
        },
    }
}

export default AuthEndpoints;