import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { FaHandshake, FaUserCircle, FaCommentDots } from "react-icons/fa";
import ProfileContainer from "../../container/profile/Profile.container";
import { Grid } from "@material-ui/core";
import MeetingListContainer from "../../container/meeting/MeetingList.container";
import ChatContainer from "../../container/chat/Chat.container";
import { Switch, Route } from "react-router";
import { Link, NavLink } from "react-router-dom";

function TabPanel({ children, value, index, ...other }) {
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			display="flex"
			flexGrow={value === index ? 1 : 0}
			justifyContent="flex-start"
			{...other}
		>
			<Box width={1}>{children}</Box>
		</Box>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		// backgroundColor: theme.palette.background.paper,
		display: "flex",
		minHeight: 200,
	},
	tabs: {
		backgroundColor: "transparent",
		opacity: 1,
		marginTop: '0.2rem',
		// "& .MuiSvgIcon-root": {
		// 	marginRight: "20px",
		// },
		"& .MuiTab-root": {
			fontFamily: "LiberGrotesque-Bold",
			textTransform: "capitalize",
			overflow: "visible",
		},
	},
	tab: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "center",
		color: '#796D57',
		"& .MuiTouchRipple-root": {
			opacity: 0,
			width: "100%"
		},
	},
	link: {
		backgroundColor: "transparent",
		fontSize: "1rem",
		textDecoration: 'none'
	},
	activeLink: {
		opacity: 1,
		backgroundColor: "#E5DED4",
		fontSize: "1rem",
		textDecoration: 'none',
		width: '100%',
		textAlign: "center"
	}
}));

const styleIcon = {
	margin: "0rem 1.5rem 3px 0rem",
};

export default function MenuComponent() {
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const pathname = window.location.pathname;

	const handleChange = useCallback((event, value) => {
		setValue(value);
	}, []);

	return (
		<Grid container>
			<Grid xs={12}>
				<Tabs
					orientation="horizontal"

					value={value}
					onChange={handleChange}
					TabIndicatorProps={{
						style: {
							height: "0px",
						}
					}}
					className={classes.tabs}
				>
					<Grid container >
						<Grid item xs={4} className={classes.tab}>
							<NavLink activeClassName={classes.activeLink} className={classes.link} to="/dashboard/calendar" style={{ textDecoration: "none" }}>
								<Tab
									classes={{ wrapper: classes.tab }}
									label="Appuntamenti"
									{...a11yProps(0)}/>
							</NavLink>
						</Grid>
						<Grid item xs={4} className={classes.tab}>
							<NavLink activeClassName={classes.activeLink} to="/dashboard/profile" style={{ textDecoration: "none" }}>
								<Tab
									classes={{ wrapper: classes.tab }}
									label="Profilo"
									{...a11yProps(1)}/>
							</NavLink>
						</Grid>
						<Grid item xs={4} className={classes.tab}	>
							<NavLink activeClassName={classes.activeLink} to="/dashboard/chat" style={{ textDecoration: "none" }}>
								<Tab
									classes={{ wrapper: classes.tab }}
									label="Chat"
									{...a11yProps(2)}/>
							</NavLink>
						</Grid>
					</Grid>
				</Tabs>
			</Grid>
			<Grid xs={12}>
				<Switch>
					<Route path="/dashboard/calendar">
						<TabPanel index={0} value={value}>
							<MeetingListContainer />
						</TabPanel>
					</Route>
					<Route path="/dashboard/profile">
						<TabPanel index={1} value={value}>
							<ProfileContainer />
						</TabPanel>
					</Route>
					<Route path="/dashboard/chat">
						<TabPanel index={2} value={value}>
							<ChatContainer />
						</TabPanel>
					</Route>
				</Switch>
			</Grid>
		</Grid>
	);
}
