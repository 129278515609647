import React, { useState } from "react";
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import FoodBuyersContainer from "../container/food/FoodBuyers.container";
import FoodImg from "../assets/img/food.jpg";
import { useTranslation } from 'react-i18next';
import FiltersLayout from "../layout/Filters.layout";
import FoodBuyersFilterContainer from "../container/food/FoodBuyersFilter.container";

const FoodBuyersPage = () => {

    const [filter, setFilter] = useState(null)
    const { t } = useTranslation()

    return (
        <FiltersLayout
            header={
                <CatalogueHeaderComponent
                    img={FoodImg}
                    title={t('food.products.title')}
                    subtitle={t('food.products.subtitle')}
                />}
            filters={<FoodBuyersFilterContainer setFilter={setFilter} />}
            list={<FoodBuyersContainer filter={filter}/>} />
    );
};

export default FoodBuyersPage;