import agrifood from './agrifood';
import tourism from './tourism';
import user from './user';
import auth from './auth';
import appointments from './appointments';
import conversations from './conversations';

const settings = {
    baseURL: ''
}

export const Endpoints = {
    agrifood, 
    tourism,
    user,
    auth,
    appointments,
    conversations
}

Object.defineProperty(Endpoints, 'baseURL', {
    enumerable: false,
    get() {
        return settings.baseURL
    },
    set(baseURL) {
        return settings.baseURL = baseURL
    }
})

export default Endpoints