import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NetworkImg from "../assets/img/network.jpg";
import HeaderContainer from "../container/header/Header.container";
import { useTranslation } from 'react-i18next';
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    text: {
        fontFamily: "LiberGrotesque-SemiBold",
    },
    link: {
        fontFamily: "LiberGrotesque-SemiBold",
        textDecoration: "underline",
        cursor: 'pointer'
    }
}));

const AboutPage = () => {

    const classes = useStyles();
    const { t } = useTranslation()

    const onClickUrl = useCallback((link) => {
        window.open(link, '_blank')
    }, [])

    return (

        <Fragment>
            <HeaderContainer />
            <CatalogueHeaderComponent img={NetworkImg} title={t("about_us.title")} subtitle={t('about_us.subtitle')} />
            <Container maxWidth={false}>
                <Box mt={4}>
                    <div className={classes.text} dangerouslySetInnerHTML={{ __html: t('about_us.content') }} />
                </Box>
                <Box mt={2}>
                    <Typography className={classes.link} variant='subtitle1' onClick={() => onClickUrl('http://www.mirabilianetwork.eu/wp-content/uploads/2018/04/Statuto-Associazione-Mirabilia-Network.pdf')}>
                        {t('about_us.link_0')}
                    </Typography> 
                </Box>
                <Box mt={2} mb={2}>
                    <Typography className={classes.link} variant='subtitle1' onClick={() => onClickUrl('http://www.mirabilianetwork.eu/wp-content/uploads/2018/04/elenco-soci-fondatori.pdf')}>
                        {t('about_us.link_1')}
                    </Typography> 
                </Box>
                <Box mt={2} mb={2}>
                    <Typography className={classes.link} variant='subtitle1' onClick={() => onClickUrl('http://www.mirabilianetwork.eu/it/')}>
                        {t('about_us.link_2')}
                    </Typography>
                </Box>

            </Container>
        </Fragment>
    );
}

export default AboutPage
