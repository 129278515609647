export const Menu = [{
	title: "menu.food&drink.title",
	items: [{
		title: "menu.food&drink.sellers",
		link: "/food_sellers",
	}, {
		title: "menu.food&drink.buyers",
		link: "/food_buyers"
	}, {
		title: "menu.food&drink.faq",
		link: "/faq/food"
	}]
}, {
	title: "menu.tourism.title",
	items: [{
		title: "menu.tourism.sellers",
		link: "/tourism_sellers"
	}, {
		title: "menu.tourism.buyers",
		link: "/tourism_buyers"
	}, {
		title: "menu.tourism.faq",
		link: "/faq/tourism"
	}]
}, {
	title: "menu.mirabilia.title",
	items: [{
		title: "menu.mirabilia.about_us",
		link: "/about"
	}, {
		title: "menu.mirabilia.network",
		link: "/partners"
	}, {
		title: "menu.mirabilia.meet",
		link: "/"
	}, {
		title: "menu.mirabilia.contacts",
		link: "/contacts"
	}]
}]