import React, { useState } from "react";
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import FoodSellersContainer from "../container/food/FoodSellers.container";
import FoodSellersFilterContainer from "../container/food/FoodSellersFilter.container";
import FiltersLayout from "../layout/Filters.layout";
import FoodImg from "../assets/img/food.jpg";
import { useTranslation } from 'react-i18next';

const FoodSellersPage = () => {

    const [filter, setFilter] = useState(null)
    const { t } = useTranslation()

    return (
        <FiltersLayout
            header={
                <CatalogueHeaderComponent
                    img={FoodImg}
                    title={t('food.products.title')}
                    subtitle={t('food.products.subtitle')}
                />}
            filters={<FoodSellersFilterContainer setFilter={setFilter} />}
            list={<FoodSellersContainer filter={filter} />} />
    );
};

export default FoodSellersPage;