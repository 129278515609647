import React, { useState } from "react";
import CatalogueHeaderComponent from "../components/header/CatalogueHeader.component";
import FoodProductsContainer from "../container/food/FoodProducts.container";
import FoodProductsFilterContainer from "../container/food/FoodProductsFilter.container";
import FiltersLayout from "../layout/Filters.layout";
import FoodImg from "../assets/img/food.jpg";
import { useTranslation } from 'react-i18next';


const FoodProductsPage = () => {

    const [filter, setFilter] = useState(null)
    const { t } = useTranslation()

    return (
        <FiltersLayout
            header={<CatalogueHeaderComponent
                img={FoodImg}
                title={t('food.products.title')}
                subtitle={t('food.products.subtitle')}
            />}
            filters={<FoodProductsFilterContainer setFilter={setFilter} />}
            list={<FoodProductsContainer filter={filter} />} />
    );
};

export default FoodProductsPage;
