import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '0.3rem',
    width: '0.9rem',
    height: '0.9rem',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    'input:hover ~ &': {
      backgroundColor: '#f0cccc',
    },
  },
  checkedIcon: {
    backgroundColor: '#B91818',
    'input:hover ~ &': {
      backgroundColor: '#941919',
    },
  },
});

const StyledCheckboxComponent = ({
  onChange,
  id,
  checked,
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checked={checked}
      onChange={onChange}      
    />
  );
}
export default StyledCheckboxComponent