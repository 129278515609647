import FoodImg from '../assets/img/food.jpg'
import TourismImg from '../assets/img/tourism.jpg'
export const CategoryCards = [
  {
    title: "home.food&drink.title",
    subtitle: "home.food&drink.subtitle",
    buttonTitle: "home.food&drink.button",
    image: FoodImg,
    link: '/food_products'
  },
  {
    title: "home.tourism.title",
    subtitle: "home.tourism.subtitle",
    buttonTitle: "home.tourism.button",
    image: TourismImg,
    link: '/tourism_sellers'
  }
]