import React, { Fragment } from 'react'
import useSWRRequest from '../../core/useSWRRequest'
import Requests from '../../core/requests'
import LoadingComponent from '../../components/common/Loading.component';
import DetailHeaderComponent from '../../components/header/DetailHeader.component';
import FoodImg from '../../assets/img/food.jpg'
import { useSelector } from 'react-redux';

const FoodSellerDetailContainer = ({
	seller
}) => {

	const { loading, data } = useSWRRequest(Requests.agrifood.seller, { seller })
	const { user } = useSelector(state => state.user)

	return (
		<Fragment>
			{loading && <LoadingComponent />}
			<DetailHeaderComponent
				seller_id={seller}
				img={FoodImg}
				name={data?.company_name}
				description={''}
				territory={data?.area.name}
				country={data?.country}
				chamber_commerce={data?.chamber_commerce.name}
				city={data?.city}
				web_site={data?.web_site}
				logo={data?.logo}
				user={user} />
		</Fragment>

	)
}

export default FoodSellerDetailContainer