export const AppointmentsEndpoints = {
    basePath: '/api/v1/appointments/',
    endpoints: {
        agrifood_meeting: {
            path: '/agrifood-meeting/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        add_agrifood_meeting: {
            path: '/agrifood-meeting/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        tourism_meeting: {
            path: '/tourism-meeting/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        add_tourism_meeting: {
            path: '/tourism-meeting/',
            method: 'POST',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        remove_tourism_meeting: {
            path: '/tourism-meeting/{{id}}',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        remove_agrifood_meeting: {
            path: '/agrifood-meeting/{{id}}',
            method: 'DELETE',
            get: 'data',
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },

    }
}

export default AppointmentsEndpoints;