import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';

const ChatComponent = ({
    message,
    user
}) => {
    
    return (
        <Fragment>
            <ListItem key={message.id}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ListItemText align={user.id === message.user_from ? 'right' : 'left'} primary={message.text}></ListItemText>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItemText align={user.id === message.user_from ? 'right' : 'left'} secondary={moment(message.timestamp).format('hh:mm')}></ListItemText>
                        </Grid>
                    </Grid>
                </ListItem>
        </Fragment>
    );
}

export default ChatComponent;