import { Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import MeetingCardGridComponent from "../../cards/MeetingCardGrid.component";
import MeetingCardListComponent from "../../cards/MeetingCardList.component";
import CreateMeetingComponent from "./CreateMeeting.component";
import DisplayStyleComponent from "./DisplayStyle.component";
import { useSelector } from 'react-redux';
import { AppointmentToPDF } from '../../../utility/pdf';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
	root: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: 'transparent',
		padding: "3rem"
	},
	title: {
		fontFamily: "LiberGrotesque-Bold",
		color: "#796D57",
		fontSize: "2rem",
	},
	subtitle: {
		fontFamily: "LiberGrotesque-SemiBold",
		color: "#796D57",
	},
	filter: {
		margin: "2rem 0rem",
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	meetingWrapper: {
		backgroundColor: "#F8F7F4",
		padding: "1rem",
	},
	meetingTitle: {
		marginLeft: "2rem",
		marginTop: "1rem",
		fontFamily: "LiberGrotesque-Bold",
		fontSize: "20px",
		color: "#796D57",
	},
});

const MeetingComponent = ({
	setOpenAdd = () => { },
	data = []
}) => {

	const classes = useStyles();
	const { t } = useTranslation()
	const [print, setPrint] = useState(false)
	const [selected, setSelected] = useState(0)
	const { user } = useSelector(state => state.user)

	const grouped = _.groupBy(_.orderBy(data, ['time'], ['asc']), 'date')

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Typography className={classes.title}>{t("private.menu.appointments.title")}</Typography>
				<Typography className={classes.subtitle}>
					{t("private.menu.appointments.subtitle")}
				</Typography>
			</Grid>

			<Grid container className={classes.filter}>
				<Grid item xs={12} md={9}>
					{user.type.id === 2 || user.type.id === 3 && data?.length < 6? <CreateMeetingComponent setOpenAdd={setOpenAdd} /> : null}
					{user.type.id === 4 || user.type.id === 5 && data?.length < 19 ? <CreateMeetingComponent setOpenAdd={setOpenAdd} /> : null}
				</Grid>
				<Grid item xs={12} md={3}>
					<DisplayStyleComponent setSelected={setSelected} setPrint={setPrint} />
				</Grid>
			</Grid>
			
			{print &&
				<AppointmentToPDF					
					user={user}
					appointmentList={_.orderBy(data, ['date'], ['asc'])}
					onAfterPrint={() => setPrint(false)} />}


			{Object.keys(grouped).map((key) =>
				<Grid key={key} item xs={12} className={classes.meetingWrapper}>
					<Typography className={classes.meetingTitle}>{key}</Typography>
					<Grid container>
						{grouped[key].map((meeting, index) => (
							selected === 0 ?
								<Grid key={index} item xs={12} sm={6} md={4}>
									<MeetingCardGridComponent
										id={meeting.id}
										date={meeting.date}
										time={meeting.time}
										seller={meeting.seller?.company_name}
										buyer={meeting.buyer?.company_name}
										url={meeting.url} />
								</Grid> :
								<Grid key={index} item xs={12} >
									<MeetingCardListComponent
										id={meeting.id}
										date={meeting.date}
										time={meeting.time}
										seller={meeting.seller?.company_name}
										buyer={meeting.buyer?.company_name}
										url={meeting.url} />
								</Grid>
						))}
					</Grid>
				</Grid>
			)}


		</Grid>
	);
};

export default MeetingComponent;
