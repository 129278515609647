import React, { Fragment, useCallback, useState } from 'react'
import ModalComponent from '../../components/common/Modal.component';
import { Button, Typography, Divider, makeStyles, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import DeleteMeetingComponent from '../../components/private/meeting/DeleteMeeting.component';
import Requests from '../../core/requests'
import useSWRRequest from '../../core/useSWRRequest'

const useStyles = makeStyles({
	title: {
		fontFamily: 'LiberGrotesque-Bold',
		padding: '1rem',
		fontSize: '1.5rem',
		wordBreak: 'break-word',
	},
	buttonWrapper: {
		display: "flex",
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		padding: '1rem'
	},
	confirm: {
		borderRadius: '0.5rem',
		backgroundColor: 'green',
		color: '#fff',
		fontFamily: 'LiberGrotesque-Regular',
		wordBreak: 'break-word',
		marginBottom: '1rem',
		"&:hover": {
			backgroundColor: 'green',
			color: '#fff',
		}
	},
	keep: {
		borderRadius: '0.5rem',
		fontFamily: 'LiberGrotesque-Regular',
		wordBreak: 'break-word',
	},
})

const AppointmentDeleteContainer = ({
	id
}) => {

	const classes = useStyles()

	const [openAdd, setOpenAdd] = useState(false)
	const { token, user } = useSelector(state => state.user)
	const { mutate } = useSWRRequest(user.type.id === 2 || user.type.id === 3 ? Requests.appointments.agrifood_meeting : Requests.appointments.tourism_meeting, { token: token.access })

	const onSetOpenAdd = useCallback(() => {
		setOpenAdd(!openAdd)
		mutate()
	}, [openAdd])


	const onDeleteAction = useCallback(() => {

		const data = {        
            token: token.access,
            id: id
        }

		if (user.type.id === 2 || user.type.id === 3) {
            Requests.appointments.remove_agrifood_meeting(data).then(() => onSetOpenAdd())
        } else if (user.type.id === 4 || user.type.id === 5) {
            Requests.appointments.remove_tourism_meeting(data).then(() => onSetOpenAdd())
        }
	}, [])


	return (
		<Fragment>
			<DeleteMeetingComponent setOpenAdd={onSetOpenAdd} />
			<ModalComponent
				title='Conferma cancellazione'
				onClose={onSetOpenAdd}
				open={openAdd}
				fullWidth={true}
				maxWidth='md'>
				<Typography className={classes.title}>Sei sicuro di voler cancellare questo appuntamento?</Typography>
				<Divider />
				<Grid container className={classes.buttonWrapper}>
					<Grid item>
						<Button variant='contained' className={classes.confirm} onClick={onDeleteAction}>Si, cancella appuntamento</Button>
					</Grid>
					<Grid item>
						<Button variant='contained' className={classes.keep} onClick={onSetOpenAdd}>No, mantieni appuntamento</Button>
					</Grid>
				</Grid>
			</ModalComponent >
		</Fragment >

	)
}

export default AppointmentDeleteContainer;