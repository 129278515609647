import _ from 'lodash';

export const ConversationsEndpoints = {
    basePath: '/api/v1',
    endpoints: {
        listChats: {
            path: '/conversations/chats/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                refreshInterval: 2000
            }
        },
        retrieveChats: {
            path: '/conversations/chats/{{id}}/',
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false,
                refreshInterval: 2000
            }
        },
        newMessage: {
            path: '/conversations/messages/',
            method: 'POST',
            get: 'data',
            auth: true,
            fallback: null,
        },
        newChat: {
            path: '/conversations/chats/',
            method: 'POST',
            auth: true,
            get: 'data',
            fallback: null,
        },
    }
}

export default ConversationsEndpoints;