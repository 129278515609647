import React, { Fragment, useMemo } from "react";
import useSWRRequest from "../../core/useSWRRequest";
import Requests from "../../core/requests";
import { Grid } from "@material-ui/core";
import ProductCardComponent from "../../components/cards/ProductCard.component";
import LoadingComponent from "../../components/common/Loading.component";
import PaginationLayout from "../../layout/Pagination.layout";
import _ from 'lodash'

const FoodProductsContainer = ({
	filter
}) => {

	const { loading, data } = useSWRRequest(Requests.agrifood.products);
	const dataFilter = useMemo(() => {

		if (filter) {
			let newData = data

			Object.keys(filter).forEach((key) => {

				if (filter[key].length > 0) {

					switch (key) {

						case 'area':
							newData = newData.filter((item) => _.includes(filter[key], item.seller.area.id))
							break;

						case 'categories':
							newData = newData.filter((item) => _.includes(filter[key], item.category.id))
							break;

						case 'producing_capacities':
							newData = newData.filter((item) => _.includes(filter[key], item.producing_capacity.id))
							break;

						case 'shelf_lifes':
							newData = newData.filter((item) => _.includes(filter[key], item.shelf_life.id))
							break;

						case 'packages':
							newData = newData.filter((item) => _.includes(filter[key], item.package.id))
							break;

						case 'chamber_commerce':
							newData = newData.filter((item) => _.includes(filter[key], item.seller.chamber_commerce.id))
							break;

						default:
							break;
					}
				}

			})

			return newData
		}

		return data

	}, [filter, data])

	return (
		<PaginationLayout data={dataFilter}>
			{({ page, slice }) =>
				<Fragment>
					<Grid item xs={12}>
						{loading && <LoadingComponent />}
					</Grid>
					{dataFilter.slice(page * slice, page * slice + slice).map((item) => (
						<Grid item xs={12} sm={6} md={4} key={item.id}>
							<ProductCardComponent
								productName={item.name}
								category={item.category}
								productId={item.id}
								image={item.image}
							/>
						</Grid>
					))}
				</Fragment>
			}
		</PaginationLayout>

	);
};

export default FoodProductsContainer;
