import React from 'react'
import { useSelector } from 'react-redux'
import ProfileComponent from '../../components/private/profile/Profile.component';
import { useTranslation } from 'react-i18next';

const ProfileContainer = () => {

    const { user } = useSelector(state => state.user)
    const { i18n } = useTranslation()

    return (
        <ProfileComponent
            logo={user.company.logo}
            first_name={user.first_name}
            last_name={user.last_name}
            email={user.company.email}
            web_site={user.company.web_site}
            description={user.company[`description_${i18n.language === 'it'? 'ita' : 'eng'}`]} />
    )
}

export default ProfileContainer;