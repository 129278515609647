import React from 'react'
import { makeStyles, IconButton, Typography, Box } from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';

const useStyles = makeStyles({
  root: {
    color: '#796D57',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-body1': {
      fontFamily: 'LiberGrotesque-Bold',
    },
    '& .MuiIconButton-root': {
      color: '#796D57',
    }
  },
})

const CreateChatComponent = ({
  setOpenAdd
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} onClick={setOpenAdd}>
      <IconButton>
        <AddCircleOutlinedIcon fontSize="large" />
      </IconButton>
      <Typography>Crea nuova chat</Typography>
    </Box>
  )
}

export default CreateChatComponent