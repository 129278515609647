import Image_1 from '../assets/img/territories/image_1.png'
import Image_2 from '../assets/img/territories/image_2.png'
import Image_3 from '../assets/img/territories/image_3.png'
import Image_4 from '../assets/img/territories/image_4.png'
import Image_5 from '../assets/img/territories/image_5.png'
import Image_6 from '../assets/img/territories/image_6.png'
import Image_7 from '../assets/img/territories/image_7.png'
import Image_8 from '../assets/img/territories/image_8.png'
import Image_9 from '../assets/img/territories/image_9.png'
import Image_10 from '../assets/img/territories/image_10.png'
import Image_11 from '../assets/img/territories/image_11.png'
import Image_12 from '../assets/img/territories/image_12.png'
import Image_13 from '../assets/img/territories/image_13.png'
import Image_14 from '../assets/img/territories/image_14.png'
import Image_15 from '../assets/img/territories/image_15.png'
import Image_16 from '../assets/img/territories/image_16.png'
import Image_17 from '../assets/img/territories/image_17.png'


export const Territories = [Image_1, Image_2, Image_3, Image_4, Image_5, Image_6, Image_7, Image_8, Image_9, Image_10, Image_11, Image_12, Image_13, Image_14, Image_15, Image_16, Image_17]