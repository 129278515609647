import React, { useCallback, useMemo, Fragment } from 'react'
import { Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup';
import ChatFormComponent from '../../components/form/ChatForm.component';
import LoadingComponent from "../../components/common/Loading.component";
import useSWRRequest from '../../core/useSWRRequest';
import Requests from '../../core/requests';
import _ from 'lodash';

export const ValidationSchema = {
    schema: Yup.object().shape({
        user_to: Yup.number().required('Campo obbligatorio'),
    }),
}

const ChatAddContainer = ({
    onClose = () => { },
}) => {

    const { token, user } = useSelector(state => state.user)
    const { data, loading } = useSWRRequest(user.type.id === 2 && Requests.agrifood.buyers || user.type.id === 3 && Requests.agrifood.sellers || user.type.id === 4 && Requests.tourism.buyers || user.type.id === 5 && Requests.tourism.sellers, { token: token.access })
    const { data: chatList } = useSWRRequest(Requests.conversations.listChats, { token: token.access });

    const userFrom = chatList.map((item) => item.user_from)
    const userTo = chatList.map((item) => item.user_to)

    const onSuccess = useCallback(() => {
        onClose()
    }, [])

    const onFailure = useCallback(() => {

    }, [])

    const onSubmit = useCallback((values) => {

        if (_.includes(userFrom, values.user_to) || _.includes(userTo, values.user_to)) {
            alert('The chat already exists')
        } else {
            Requests.conversations.newChat({ ...values, token: token.access }).then(() => onSuccess()).catch((error) => onFailure(error))
        }

    }, [])



    return (
        <Fragment>
            {loading && <LoadingComponent />}
            {!loading &&
                <ChatFormComponent data={data} onClick={onSubmit}/>
            }
        </Fragment>
    )
}

export default ChatAddContainer;